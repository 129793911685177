import * as React from 'react';
import { Icon, IconName } from '../Icon';
import { Button, ButtonProps } from './Button';
import { NextButton } from './NextButton';

type Props = ButtonProps & {
  icon: IconName;
  isLoading: boolean;
};
export const ButtonIconLoading: React.FC<Props> = ({ icon, isLoading, children, ...props }) => (
  <Button {...props} disabled={isLoading || props.disabled}>
    {isLoading ? <Icon icon="spinner" fixedWidth className="fa-pulse fa-3x fa-fw" /> : <Icon fixedWidth icon={icon} />}
    {children}
  </Button>
);

type NextProps = React.ComponentProps<typeof NextButton> & {
  icon: IconName;
  isLoading: boolean;
};

export const NextButtonIconLoading: React.FC<NextProps> = ({ icon, isLoading, children, ...props }) => (
  <NextButton {...props} disabled={isLoading || props.disabled}>
    {isLoading ? (
      <NextButton.Icon icon="spinner" fixedWidth className="fa-pulse fa-3x fa-fw" />
    ) : (
      <NextButton.Icon fixedWidth icon={icon} />
    )}
    {children}
  </NextButton>
);
