/**
 * Rounds a number to max two digits.
 * numbers are rounded to the first two non zero digits.
 * e.g. 0.00000020666 -> 0.00000021
 */ export function roundToFirstTwoNonZeroDigits(n) {
    if (n === 0) {
        return 0;
    }
    return parseFloat(n.toFixed(1 - Math.floor(Math.log(n) / Math.log(10))));
}
