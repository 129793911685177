import { css, Global } from '@emotion/react';
import * as React from 'react';

const semiBold = require('./inter-semi-bold.woff2');
const regular = require('./inter-regular.woff2');

export const GlobalFontStyles: React.FC = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          src:
            local('Inter Semi Bold'),
            local('Inter-Semi-Bold'),
            url(${semiBold}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212,
            U+2215;
        }

        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          src:
            local('Inter Regular'),
            local('Inter-Regular'),
            url(${regular}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212,
            U+2215;
        }
      `}
    />
  );
};
