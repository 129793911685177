import React from 'react';
import { assertIsDefined } from '../assertIsDefined';
export function useControllableState({ onChange, ...options }) {
    const setValueRef = React.useRef();
    setValueRef.current = options.setValue;
    const [uncontrolledValue, setUncontrolledValue] = React.useState(options.initialValue);
    const isControlled = 'value' in options;
    const value = isControlled ? options.value : uncontrolledValue;
    const setValue = React.useCallback((value)=>{
        if (isControlled) {
            assertIsDefined(setValueRef.current);
            setValueRef.current(value);
        } else {
            setUncontrolledValue(value);
        }
        if (onChange) {
            onChange(value);
        }
    }, []);
    return [
        value,
        setValue
    ];
}
