import { isFunction } from 'lodash';
import * as React from 'react';
export const mergeRefs = (...refs)=>(ref)=>{
        refs.forEach((r)=>{
            if (r) {
                if (isFunction(r)) {
                    r(ref);
                } else {
                    r.current = ref;
                }
            }
        });
    };
