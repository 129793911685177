import * as React from 'react';
import { TOOLTIP_DELAY, TooltipInlineBlock, TooltipPlacement } from '../tooltip';
import { ButtonStylingProps, StyledButton } from './ButtonStyles';

export type ButtonProps = Omit<ButtonStylingProps, 'renderAsDisabled'> &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    tooltip?: React.ReactElement | null;
    tooltipPlace?: TooltipPlacement;
    /** Id to access button in test cases */
    testAnchorId?: string;
    children?: React.ReactNode;
  };

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ tooltip, tooltipPlace, testAnchorId, size = 'medium', ...props }, ref) => {
    function handleDisabled(e: React.MouseEvent<HTMLButtonElement>) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    const btn = (
      <StyledButton
        ref={ref}
        type="button"
        renderAsDisabled={props.disabled}
        data-test={testAnchorId}
        {...props}
        size={size}
        disabled={false} // ensure that mouse events are still active
        onClick={!props.disabled ? props.onClick : handleDisabled}
      />
    );

    if (tooltip) {
      return (
        <TooltipInlineBlock className={props.className} content={tooltip} delay={TOOLTIP_DELAY} place={tooltipPlace}>
          {btn}
        </TooltipInlineBlock>
      );
    } else {
      return btn;
    }
  },
);
