import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Theme } from '@txt/core.styles';
import { Link } from 'react-router-dom';

export type ButtonVariant = 'contained' | 'text' | 'outlined' | 'underlined' | 'white-dark-text';
type ButtonColor = 'default' | 'primary' | 'inverted';
type ButtonSize = 'medium' | 'large' | 'extraLarge';
type ButtonHeight = 32 | 40 | 60;

export const ClearedButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  height: initial;
`;

export type ButtonStylingProps = {
  /** defaults to 'contained' */
  variant?: ButtonVariant;
  color?: ButtonColor;
  renderAsDisabled?: boolean;
  disabled?: boolean;

  /** defaults to 'medium' */
  size?: ButtonSize;
};

const buttonHeightsBySize: Record<ButtonSize, ButtonHeight> = {
  medium: 32,
  large: 40,
  extraLarge: 60,
};

const buttonFontSizesBySize: Record<ButtonSize, keyof Theme['typography']['fontSizes']> = {
  medium: 'sm',
  large: 'xl3',
  extraLarge: 'xl5',
};

const buttonCoreStyles = (
  props: {
    theme: Theme;
  } & ButtonStylingProps,
) => css`
  font-size: ${props.theme.typography.fontSizes[buttonFontSizesBySize[props.size ?? 'medium']]};
  font-weight: ${props.theme.typography.fontWeights.semiBold};
  letter-spacing: 0.5px;
  border-radius: 2px;
  border: none;
  padding: 0 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin: 0px;
  height: ${buttonHeightsBySize[props.size ?? 'medium']}px;
  outline: none;
  text-decoration: none;

  svg {
    font-size: 1em;
  }

  &.icon-only {
    padding: 0 12px;
  }

  &.icon-only--round {
    min-width: 32px;
    border-radius: 50% 50%;
    border-width: 2px;
    padding: 0;
    font-size: 1.2rem;
  }

  cursor: ${props.renderAsDisabled || props.disabled ? 'initial' : 'pointer'};

  &:hover {
    text-decoration: none;
  }

  & + button,
  & + a,
  & + & {
    margin-left: 1em;
  }

  ${(!props.variant || props.variant === 'contained') &&
  `
    background: ${getOutlineColor(props, props.theme.palette.grey[200])};
    color: ${props.color === 'inverted' ? props.theme.palette.primary[500] : '#fff'};
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.5);

    &:hover {
      background: ${
        props.renderAsDisabled || props.disabled
          ? props.theme.palette.grey[100]
          : props.color === 'inverted'
            ? props.theme.palette.grey[50]
            : props.theme.palette.primary[600]
      };
    }
  `}
  ${(props.variant === 'text' || props.variant === 'outlined' || props.variant === 'underlined') &&
  `
    color: ${getOutlineColor(props, props.theme.palette.text)};
    background: transparent;
    border: ${props.variant === 'outlined' ? '1px solid' : 'none'};

    &:hover {
      color: ${
        props.renderAsDisabled || props.disabled
          ? props.theme.palette.grey[100]
          : !props.color || props.color === 'default'
            ? props.theme.palette.primary[500]
            : props.theme.palette.text
      };
    }
  `}
  ${props.variant === 'underlined' &&
  `
    padding: 0;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0.3em;
      border-bottom: 2px solid;
      left: 0;
      right: 0;
    }
  `}
  ${props.variant === 'text' &&
  `
    padding: 0 12px;
  `}
  /** TODO: Remove redundant props when button cmp is implemented after new design */
  ${props.variant === 'white-dark-text' &&
  `
    background: ${
      props.renderAsDisabled || props.disabled ? props.theme.palette.grey['100'] : props.theme.palette.white
    };    
    color: ${props.theme.palette.text};

    padding: 12px;
    height: inherit;
    font-size: ${props.size === 'large' ? '1.12rem' : '1rem'};
    line-height: 1.5rem;

    &:hover {
      background: ${
        props.renderAsDisabled || props.disabled ? props.theme.palette.grey['100'] : props.theme.palette.grey['50']
      };

    &:focus {
        ${(!props.renderAsDisabled || !props.disabled) && `background: ${props.theme.palette.grey['100']};`}
    }    
  `}
`;

function getOutlineColor(props: ButtonStylingProps & { theme: Theme }, baseColor: string) {
  if (props.renderAsDisabled) {
    return props.theme.palette.grey[100];
  }
  if (props.color === 'inverted') {
    return '#fff';
  }

  return props.color === 'primary' ? props.theme.palette.primary[500] : baseColor;
}

export const StyledButton = styled.button<ButtonStylingProps>`
  ${buttonCoreStyles}
`;

/** Button styles for a Link component. */
export const LinkButton = styled(Link, { shouldForwardProp: (key) => key !== 'variant' && key !== 'color' })`
  ${buttonCoreStyles}
  line-height: 30px;
  height: 30px;
  text-decoration: none;
`;

export const UnstyledLink = styled(Link)`
  display: block;
  color: inherit;
  text-decoration: inherit;
  &:hover,
  &:focus,
  &:active {
    text-decoration: inherit;
  }
`;

/** Button styles for a Link component. */
export const ExternalLinkButton = styled.a`
  ${buttonCoreStyles}
  line-height: 30px;
  height: 30px;
  text-decoration: none;
`;

export const ActionLinkBtn = styled.span`
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => props.theme.palette.text};

  &:hover {
    color: ${(props) => props.theme.palette.primary[500]};
  }
`;
