export class QueryablePromise {
    isResolved = false;
    isRejected = false;
    promise;
    result = undefined;
    error;
    get isFulfilled() {
        return this.isResolved || this.isRejected;
    }
    constructor(promise){
        this.promise = promise;
        this.promise.then((result)=>{
            this.isResolved = true;
            this.result = result;
            delete this.error;
        }, (error)=>{
            this.isRejected = true;
            this.error = error;
            delete this.result;
        });
    }
}
/**
 * A promise wrapper which can be canceled to prevent e.g. state
 * updates on unmounted components.
 */ export class CancelablePromise {
    innerPromise;
    outerPromise;
    canceled;
    constructor(promise){
        this.innerPromise = promise;
        this.outerPromise = new Promise((resolve, reject)=>{
            this.buildPromise(resolve, reject);
        });
        this.canceled = false;
    }
    cancel() {
        this.canceled = true;
    }
    isCanceled() {
        return this.canceled;
    }
    buildPromise(resolve, reject) {
        this.innerPromise.then((value)=>{
            if (!this.isCanceled()) {
                // accept
                resolve(value);
            } else {
                // reject due to cancellation
                reject({
                    isCanceled: true
                });
            }
        }).catch((error)=>{
            if (this.isCanceled()) {
                // reject due to cancellation
                reject({
                    isCanceled: true
                });
            } else {
                // reject due to error
                reject(error);
            }
        });
    }
    then(onfulfilled, onrejected) {
        return this.outerPromise.then(onfulfilled, onrejected);
    }
    catch(handler) {
        return this.outerPromise.catch(handler);
    }
}
export function cancelable(promise) {
    if (promise instanceof CancelablePromise) {
        return promise;
    }
    return new CancelablePromise(promise);
}
export function defaultOnError(promise, alternative) {
    return new Promise((resolve, reject)=>{
        promise.then(resolve).catch((e)=>{
            resolve(alternative);
        });
    });
}
export function isThenable(x) {
    return !!x && x instanceof Object && 'then' in x && x.then !== undefined && typeof x.then === 'function';
}
/**
 * "sleep" for async functions
 * the returned promise resolves after the given timeout
 *
 * use as `await sleep(1000)`
 */ export function sleep(timeout) {
    return new Promise((resolve, reject)=>{
        window.setTimeout(resolve, timeout);
    });
}
export function isCancelablePromiseError(e) {
    return !!(e && typeof e === 'object' && 'isCanceled' in e && typeof e.isCanceled === 'boolean');
}
/** Returns true if the error object is a canceled error. */ export function isErrorCanceled(ex) {
    return isCancelablePromiseError(ex) && ex.isCanceled === true;
}
