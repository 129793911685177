import * as React from 'react';
import { Icon, IconName } from '../Icon';
import { Button, ButtonProps } from './Button';

export type ButtonIconOnlyProps = ButtonProps & {
  icon: IconName;
  /** use for rotate etc */
  iconClassName?: string;
  tooltip?: ButtonProps['tooltip'];
  tooltipPlace?: ButtonProps['tooltipPlace'];
};

export const ButtonIconOnly = React.forwardRef<HTMLButtonElement, ButtonIconOnlyProps>((props, ref) => {
  const { icon, className = '', iconClassName = '', children, ...rest } = props;

  return (
    <Button ref={ref} variant="text" className={`${className} icon-only`} {...rest}>
      <Icon icon={icon} className={iconClassName} />
    </Button>
  );
});
