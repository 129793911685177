import * as Immutable from 'immutable';
/** Creates a map from an arbitrary collection. */ export function createImmutableMap(collection, getKey) {
    return Immutable.Map().withMutations((map)=>{
        for (const item of collection){
            map.set(getKey(item), item);
        }
    });
}
/**
 * Creates a typed map from the given object
 *
 * this is just a simple wrapper around the Map({}) constructor to get a typed key
 */ export function createImmutableMapFromObject(obj) {
    return Immutable.Map(obj);
}
export function setManyInImmutableMap(map, items) {
    return map.withMutations((mutableMap)=>{
        for (const item of items){
            mutableMap.set(item[0], item[1]);
        }
    });
}
/**
 * Updates the value within the given map iff the key exists
 *
 * this is a noop if the key is not found
 *
 * @returns the updated map
 */ export function updateIfExists(map, key, updater) {
    if (!map.has(key)) {
        return map; // noop
    }
    return map.update(key, (node)=>updater(node));
}
/** Merges/reduces the given maps to a single map */ export function mergeImmutableMaps(merge, ...maps) {
    return Immutable.Map().withMutations((output)=>{
        for (const map of maps){
            for (const [k, v] of map){
                const others = output.get(k);
                const newValue = merge(v, others);
                output.set(k, newValue);
            }
        }
    });
}
