import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const linkStyles = css`
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary[500]};
  ${linkStyles}
`;
