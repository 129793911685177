/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  JSON: { input: any; output: any };
};

export type AlternativeSolutionResp = {
  __typename: 'AlternativeSolutionResp';
  message: Maybe<Scalars['String']['output']>;
  solution: Maybe<Solution>;
  status: AlternativeSolutionStatus;
};

export type AlternativeSolutionStatus = 'ERROR' | 'SUCCESS';

export type Architecture =
  | 'amd64'
  | 'arm'
  | 'arm64'
  | 'armv7'
  | 'i386'
  | 'ia64'
  | 'mips'
  | 'mips32'
  | 'mips64'
  | 'mips64be'
  | 'mips64le'
  | 'ppc'
  | 'ppc32'
  | 'ppc64'
  | 'ppc64be'
  | 'ppc64le'
  | 's390x';

export type BackupPriceItem = BasePriceItem & {
  __typename: 'BackupPriceItem';
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type BasePriceItem = {
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type BaseStorageInfo = {
  maxIOPSPerInstance: Maybe<Scalars['Int']['output']>;
  maxIOPSPerVolume: Maybe<Scalars['Int']['output']>;
  maxThroughputInMbPSPerInstance: Maybe<Scalars['Int']['output']>;
  maxThroughputInMbPSPerVolume: Maybe<Scalars['Int']['output']>;
  storageType: Maybe<StorageType>;
};

export type BillingConfig = {
  __typename: 'BillingConfig';
  stripeApiKey: Scalars['String']['output'];
};

export type BillingSubscription = {
  limits: Limits;
};

export type BurstingFeePriceItem = BasePriceItem & {
  __typename: 'BurstingFeePriceItem';
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type CacheDependency = 'prodInstance' | 'product' | 'technology';

export type CarbonFootprint = {
  __typename: 'CarbonFootprint';
  co2e: Scalars['Float']['output'];
  kilowattHours: Scalars['Float']['output'];
};

export type CategoryProposal = {
  __typename: 'CategoryProposal';
  matchedParentNodes: Array<Scalars['String']['output']>;
  missingCategories: Array<Scalars['String']['output']>;
};

export type Certification = {
  __typename: 'Certification';
  aliasNames: Array<Scalars['String']['output']>;
  certifiedProducts: Array<CloudProduct>;
  certifiedProviders: Array<CloudProvider>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  imageUrl: Maybe<Scalars['String']['output']>;
  issuer: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  relatedIndustries: Maybe<IndustryType>;
  txtureId: Scalars['String']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export type CertificationConnection = {
  __typename: 'CertificationConnection';
  edges: Array<Certification>;
  paginationInfo: PaginationInfo;
};

export type CertificationCreateInput = {
  aliasNames?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  issuer?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  relatedIndustries?: InputMaybe<IndustryType>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CertificationCreateResponse = MutationResponse & {
  __typename: 'CertificationCreateResponse';
  certification: Maybe<Certification>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CertificationDeleteResponse = MutationResponse & {
  __typename: 'CertificationDeleteResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CertificationFilterInput = {
  industryType?: InputMaybe<IndustryType>;
};

export type CertificationRequirement = {
  importance: Importance;
  name: Scalars['String']['input'];
};

export type CertificationUpdateInput = {
  aliasNames?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  issuer?: InputMaybe<Scalars['String']['input']>;
  relatedIndustries?: InputMaybe<IndustryType>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CertificationUpdateResponse = MutationResponse & {
  __typename: 'CertificationUpdateResponse';
  certification: Maybe<Certification>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ChangeTechProcessedType = 'ACCEPTED' | 'DECLINED' | 'PROPOSED';

export type ChangeTechProposal = {
  __typename: 'ChangeTechProposal';
  categoryProposal: Maybe<CategoryProposal>;
  crawler: Scalars['String']['output'];
  from: Technology;
  id: Scalars['ID']['output'];
  metadata: Maybe<Scalars['String']['output']>;
  status: ChangeTechProcessedType;
  techName: Scalars['String']['output'];
  to: TechnologyTo;
  type: Maybe<ChangeTechProposalType>;
};

export type ChangeTechProposalConnection = {
  __typename: 'ChangeTechProposalConnection';
  edges: Array<ChangeTechProposal>;
  paginationInfo: PaginationInfo;
};

export type ChangeTechProposalCreateInput = {
  changes: TechnologyChangeInput;
  techName: Scalars['String']['input'];
  technology: TechnologyUpdateInput;
};

export type ChangeTechProposalCreateResponse = MutationResponse & {
  __typename: 'ChangeTechProposalCreateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  proposal: Maybe<ChangeTechProposalDB>;
  success: Scalars['Boolean']['output'];
};

export type ChangeTechProposalDB = {
  __typename: 'ChangeTechProposalDB';
  changes: TechnologyTo;
  crawler: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: ChangeTechProcessedType;
  techName: Scalars['String']['output'];
  technology: Technology;
  type: Maybe<ChangeTechProposalType>;
};

export type ChangeTechProposalType = 'CHANGED' | 'NEW';

export type CloudProduct = TenancyInterface &
  WithSourceInfo & {
    __typename: 'CloudProduct';
    aliasNames: Maybe<Array<Scalars['String']['output']>>;
    availableDataCenters: Array<Scalars['String']['output']>;
    certifications: Array<ProductCertification>;
    description: Maybe<Scalars['String']['output']>;
    firstCrawlerExecution: Maybe<Scalars['Date']['output']>;
    hasInstancesInLocation: Scalars['Boolean']['output'];
    hasPricing: Scalars['Boolean']['output'];
    hasReservedPricing: Scalars['Boolean']['output'];
    ignoredForProposals: Maybe<Scalars['Boolean']['output']>;
    imageUrl: Maybe<Scalars['String']['output']>;
    instanceTypeConfiguration: Maybe<InstanceTypeConfiguration>;
    internalDescription: Maybe<Scalars['String']['output']>;
    isATechnology: Maybe<Technology>;
    isATechnologyPath: Array<Scalars['String']['output']>;
    isDedicated: Maybe<Scalars['Boolean']['output']>;
    isPublic: Scalars['Boolean']['output'];
    landingZone: ProductLandingZone;
    lastCrawlerUpdate: Maybe<Scalars['Date']['output']>;
    name: Scalars['String']['output'];
    ownedBy: Array<Scalars['String']['output']>;
    priceInfo: Maybe<InstancePrice>;
    prodAttributes: Array<ProdAttribute>;
    productCategory: Maybe<Scalars['String']['output']>;
    productGroup: Maybe<Scalars['String']['output']>;
    productGroupPriority: Maybe<Scalars['Int']['output']>;
    productInstances: ProductInstanceConnection;
    productStatistics: Maybe<ProductStatistics>;
    productType: Maybe<CloudProductType>;
    productUrl: Maybe<Scalars['String']['output']>;
    productsInSameGroup: Array<CloudProduct>;
    provider: CloudProvider;
    providerName: Scalars['String']['output'];
    providesTechnology: Maybe<Array<Technology>>;
    slaAvailability: Maybe<Scalars['String']['output']>;
    sourceInfo: Array<SourceInfo>;
    technicalDescription: Maybe<Scalars['String']['output']>;
    terraformTemplate: Maybe<Scalars['String']['output']>;
    txtureId: Scalars['String']['output'];
    vendorLockin: Maybe<Scalars['Float']['output']>;
  };

export type CloudProducthasInstancesInLocationArgs = {
  locationName?: InputMaybe<Scalars['String']['input']>;
};

export type CloudProductproductInstancesArgs = {
  filter?: InputMaybe<ProductInstanceFilter>;
  pagination?: InputMaybe<OffsetPagination>;
  sortOptions?: InputMaybe<Array<ProductInstanceSortOption>>;
};

export type CloudProductCreateInput = {
  aliasNames?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  ignoredForProposals?: InputMaybe<Scalars['Boolean']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  instanceTypeConfiguration?: InputMaybe<InstanceTypeConfigurationInput>;
  internalDescription?: InputMaybe<Scalars['String']['input']>;
  isATechnology?: InputMaybe<Scalars['String']['input']>;
  isDedicated?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic: Scalars['Boolean']['input'];
  landingZone?: InputMaybe<ProductLandingZone>;
  name: Scalars['String']['input'];
  ownedBy?: InputMaybe<Array<Scalars['String']['input']>>;
  prodAttributes?: InputMaybe<Array<ProdAttributeInput>>;
  productGroup?: InputMaybe<Scalars['String']['input']>;
  productGroupPriority?: InputMaybe<Scalars['Int']['input']>;
  productType?: InputMaybe<CloudProductType>;
  productUrl?: InputMaybe<Scalars['String']['input']>;
  providerName: Scalars['String']['input'];
  providesTechnology?: InputMaybe<Array<Scalars['String']['input']>>;
  slaAvailability?: InputMaybe<Scalars['String']['input']>;
  sourceInfo?: InputMaybe<Array<SourceInfoInput>>;
  technicalDescription?: InputMaybe<Scalars['String']['input']>;
  terraformTemplate?: InputMaybe<Scalars['String']['input']>;
  vendorLockin?: InputMaybe<Scalars['Float']['input']>;
};

export type CloudProductCreateResponse = MutationResponse & {
  __typename: 'CloudProductCreateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  product: Maybe<CloudProduct>;
  success: Scalars['Boolean']['output'];
};

export type CloudProductDeleteResponse = MutationResponse & {
  __typename: 'CloudProductDeleteResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CloudProductID = TaxonomyID & {
  __typename: 'CloudProductID';
  idType: TechID;
  name: Scalars['String']['output'];
  providerName: Maybe<Scalars['String']['output']>;
  txtureId: Scalars['String']['output'];
};

export type CloudProductReplacement = {
  __typename: 'CloudProductReplacement';
  distance: Scalars['Float']['output'];
  product: CloudProduct;
};

export type CloudProductType = 'CaaS' | 'IaaS' | 'PaaS' | 'SaaS';

export type CloudProductUpdateInput = {
  aliasNames?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  ignoredForProposals?: InputMaybe<Scalars['Boolean']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  instanceTypeConfiguration?: InputMaybe<InstanceTypeConfigurationInput>;
  internalDescription?: InputMaybe<Scalars['String']['input']>;
  isATechnology?: InputMaybe<Scalars['String']['input']>;
  isDedicated?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic: Scalars['Boolean']['input'];
  landingZone?: InputMaybe<ProductLandingZone>;
  ownedBy?: InputMaybe<Array<Scalars['String']['input']>>;
  prodAttributes?: InputMaybe<Array<ProdAttributeInput>>;
  productGroup?: InputMaybe<Scalars['String']['input']>;
  productGroupPriority?: InputMaybe<Scalars['Int']['input']>;
  productType?: InputMaybe<CloudProductType>;
  productUrl?: InputMaybe<Scalars['String']['input']>;
  providerName?: InputMaybe<Scalars['String']['input']>;
  providesTechnology?: InputMaybe<Array<Scalars['String']['input']>>;
  slaAvailability?: InputMaybe<Scalars['String']['input']>;
  sourceInfo?: InputMaybe<Array<SourceInfoInput>>;
  technicalDescription?: InputMaybe<Scalars['String']['input']>;
  terraformTemplate?: InputMaybe<Scalars['String']['input']>;
  txtureId: Scalars['String']['input'];
  vendorLockin?: InputMaybe<Scalars['Float']['input']>;
};

export type CloudProductUpdateResponse = MutationResponse & {
  __typename: 'CloudProductUpdateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  product: Maybe<CloudProduct>;
  success: Scalars['Boolean']['output'];
};

export type CloudProvider = TenancyInterface &
  WithSourceInfo & {
    __typename: 'CloudProvider';
    aliasNames: Maybe<Array<Scalars['String']['output']>>;
    /** @deprecated Field no longer supported */
    cautions: Maybe<Scalars['String']['output']>;
    /** @deprecated Field no longer supported */
    criticalSupport: Maybe<Scalars['Boolean']['output']>;
    dataCenters: Array<DataCenter>;
    description: Maybe<Scalars['String']['output']>;
    excludedRegions: Maybe<Array<Scalars['String']['output']>>;
    /** @deprecated Field no longer supported */
    foundationDate: Maybe<Scalars['Date']['output']>;
    /** @deprecated Field no longer supported */
    freeOfferInfo: Maybe<Scalars['String']['output']>;
    hasMigrationTools: Scalars['Boolean']['output'];
    /** @deprecated Field no longer supported */
    headquarters: Maybe<Scalars['String']['output']>;
    iconImageUrl: Maybe<Scalars['String']['output']>;
    imageUrl: Maybe<Scalars['String']['output']>;
    internalDescription: Maybe<Scalars['String']['output']>;
    isPublic: Scalars['Boolean']['output'];
    key: Maybe<Scalars['String']['output']>;
    landingZones: Array<ProductLandingZone>;
    longDescription: Maybe<Scalars['String']['output']>;
    /** @deprecated Field no longer supported */
    marketShareInPercent: Maybe<Scalars['Float']['output']>;
    /** @deprecated Field no longer supported */
    mspSupport: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    /** @deprecated Field no longer supported */
    numberOfEmployees: Maybe<Scalars['Int']['output']>;
    ownedBy: Array<Scalars['String']['output']>;
    /** @deprecated Field no longer supported */
    parentOrganization: Maybe<Scalars['String']['output']>;
    prodAttributes: Array<ProdAttribute>;
    products: Array<CloudProduct>;
    providerCertifications: ProviderCertification;
    providerType: ProviderType;
    /** @deprecated Field no longer supported */
    revenue: Maybe<Scalars['String']['output']>;
    serviceModels: Array<CloudProductType>;
    sourceInfo: Array<SourceInfo>;
    stats: CloudProviderStats;
    /** @deprecated Field no longer supported */
    strengths: Maybe<Scalars['String']['output']>;
    terraformTemplate: Maybe<Scalars['String']['output']>;
    /** @deprecated Field no longer supported */
    tsanetMembership: Maybe<Scalars['Boolean']['output']>;
    txtureId: Scalars['String']['output'];
    url: Maybe<Scalars['String']['output']>;
  };

export type CloudProviderCreateInput = {
  aliasNames?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  excludedRegions?: InputMaybe<Array<Scalars['String']['input']>>;
  iconImageUrl?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  internalDescription?: InputMaybe<Scalars['String']['input']>;
  isPublic: Scalars['Boolean']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  ownedBy?: InputMaybe<Array<Scalars['String']['input']>>;
  prodAttributes?: InputMaybe<Array<ProdAttributeInput>>;
  providerType: ProviderType;
  sourceInfo?: InputMaybe<Array<SourceInfoInput>>;
  terraformTemplate?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CloudProviderStats = {
  __typename: 'CloudProviderStats';
  numberOfDataCenters: Scalars['Int']['output'];
  numberOfProductInstances: Scalars['Int']['output'];
  numberOfProducts: Scalars['Int']['output'];
  numberOfProviders: Scalars['Int']['output'];
};

export type CloudProviderUpdateInput = {
  aliasNames?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  excludedRegions?: InputMaybe<Array<Scalars['String']['input']>>;
  iconImageUrl?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  internalDescription?: InputMaybe<Scalars['String']['input']>;
  isPublic: Scalars['Boolean']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  ownedBy?: InputMaybe<Array<Scalars['String']['input']>>;
  prodAttributes?: InputMaybe<Array<ProdAttributeInput>>;
  providerType?: InputMaybe<ProviderType>;
  sourceInfo?: InputMaybe<Array<SourceInfoInput>>;
  terraformTemplate?: InputMaybe<Scalars['String']['input']>;
  txtureId: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CloudProvidersFilterInput = {
  type?: InputMaybe<ProviderType>;
};

export type CloudSearchResult = {
  __typename: 'CloudSearchResult';
  productCategories: Array<Technology>;
  products: Array<CloudProduct>;
  providers: Array<CloudProvider>;
};

export type CollectionChangeStats = {
  __typename: 'CollectionChangeStats';
  addedElements: Scalars['Int']['output'];
  collection: Scalars['String']['output'];
  deletedElements: Scalars['Int']['output'];
};

export type CollectionType = 'CloudProduct' | 'DataCenter' | 'Provider' | 'Technology' | 'Vendor';

export type Company = {
  __typename: 'Company';
  canSubscribeToFreeTrial: Scalars['Boolean']['output'];
  hasTaxId: Scalars['Boolean']['output'];
  hasValidSubscription: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  subscription: BillingSubscription;
  users: Maybe<Array<User>>;
};

export type CompanyUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyUpdateResponse = MutationResponse & {
  __typename: 'CompanyUpdateResponse';
  code: Scalars['String']['output'];
  company: Maybe<Company>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ComputedInstancePrice = {
  __typename: 'ComputedInstancePrice';
  price: Maybe<ComputedPrice>;
  terms: Maybe<PriceTerms>;
};

export type ComputedPrice = {
  __typename: 'ComputedPrice';
  avgPerMonth: Scalars['Float']['output'];
  perMonth: Scalars['Float']['output'];
  upfront: Scalars['Float']['output'];
};

export type ComputedPriceInput = {
  avgPerMonth: Scalars['Float']['input'];
  perMonth: Scalars['Float']['input'];
  upfront: Scalars['Float']['input'];
};

export type ConnectionPriceItem = BasePriceItem & {
  __typename: 'ConnectionPriceItem';
  connectionBundle: Maybe<Scalars['Int']['output']>;
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type Coordinates = {
  __typename: 'Coordinates';
  lat: Scalars['Float']['output'];
  long: Scalars['Float']['output'];
};

export type CoordinatesInput = {
  lat: Scalars['Float']['input'];
  long: Scalars['Float']['input'];
};

export type CorePriceItem = BasePriceItem & {
  __typename: 'CorePriceItem';
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  multiplierParamKey: Scalars['String']['output'];
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type CrawlerExecution = {
  __typename: 'CrawlerExecution';
  error: Maybe<Error>;
  executionInfo: Maybe<ExecutionInfo>;
  executionStatus: ExecutionStatus;
  executionTimeInMs: Scalars['Int']['output'];
  startDate: Scalars['Date']['output'];
};

export type CreateCloudProviderResponse = MutationResponse & {
  __typename: 'CreateCloudProviderResponse';
  cloudProvider: Maybe<CloudProvider>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DataCenter = TenancyInterface & {
  __typename: 'DataCenter';
  aliasNames: Maybe<Array<Scalars['String']['output']>>;
  availabilityZones: Array<Scalars['String']['output']>;
  availableProducts: Array<CloudProduct>;
  carbonEmissionTonPerkWh: Maybe<Scalars['Float']['output']>;
  dataCenterType: DataCenterType;
  description: Maybe<Scalars['String']['output']>;
  isPublic: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  landingZone: ProductLandingZone;
  location: Location;
  name: Scalars['String']['output'];
  ownedBy: Array<Scalars['String']['output']>;
  provider: CloudProvider;
  score: Maybe<DataCenterScore>;
  status: DataCenterStatus;
  txtureId: Scalars['String']['output'];
  virtualDataCenter: Maybe<Scalars['Boolean']['output']>;
};

export type DataCenterCreateInput = {
  aliasNames?: InputMaybe<Array<Scalars['String']['input']>>;
  availabilityZones: Array<Scalars['String']['input']>;
  carbonEmissionTonPerkWh?: InputMaybe<Scalars['Float']['input']>;
  dataCenterType?: InputMaybe<DataCenterType>;
  description?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  landingZone?: InputMaybe<ProductLandingZone>;
  locationName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  ownedBy?: InputMaybe<Array<Scalars['String']['input']>>;
  providerName: Scalars['String']['input'];
  status?: InputMaybe<DataCenterStatus>;
  virtualDataCenter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DataCenterCreateResponse = MutationResponse & {
  __typename: 'DataCenterCreateResponse';
  code: Scalars['String']['output'];
  dataCenter: Maybe<DataCenter>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DataCenterDeleteResponse = MutationResponse & {
  __typename: 'DataCenterDeleteResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DataCenterScore = {
  __typename: 'DataCenterScore';
  availableProducts: Scalars['Float']['output'];
  cost: Scalars['Float']['output'];
  sustainability: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type DataCenterStatus = 'ACTIVE' | 'DEPRECATED' | 'PLANNED';

export type DataCenterType = 'Government' | 'Standard';

export type DataCenterUpdateInput = {
  aliasNames?: InputMaybe<Array<Scalars['String']['input']>>;
  availabilityZones: Array<Scalars['String']['input']>;
  carbonEmissionTonPerkWh?: InputMaybe<Scalars['Float']['input']>;
  dataCenterType?: InputMaybe<DataCenterType>;
  description?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  landingZone?: InputMaybe<ProductLandingZone>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownedBy?: InputMaybe<Array<Scalars['String']['input']>>;
  provider?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DataCenterStatus>;
  virtualDataCenter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DataCenterUpdateResponse = MutationResponse & {
  __typename: 'DataCenterUpdateResponse';
  code: Scalars['String']['output'];
  dataCenter: Maybe<DataCenter>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DataCountTotal = {
  __typename: 'DataCountTotal';
  collection: Scalars['String']['output'];
  count: Scalars['Int']['output'];
};

export type DataInput = {
  __typename: 'DataInput';
  elements: Array<DataInputElement>;
  stats: DataInputStats;
};

export type DataInputElement = {
  __typename: 'DataInputElement';
  cpuFrequency: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  name: Maybe<Scalars['String']['output']>;
  numberOfCpus: Maybe<Scalars['Int']['output']>;
  numberOfElements: Scalars['Int']['output'];
  operatingSystem: Maybe<Scalars['String']['output']>;
  ramInMib: Maybe<Scalars['Float']['output']>;
  storageUsedInMiB: Maybe<Scalars['Float']['output']>;
  technology: Maybe<Scalars['String']['output']>;
  type: DataInputElementType;
};

export type DataInputElementInput = {
  cpuFrequency?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfCpus?: InputMaybe<Scalars['Int']['input']>;
  numberOfElements: Scalars['Int']['input'];
  operatingSystem?: InputMaybe<Scalars['String']['input']>;
  ramInMib?: InputMaybe<Scalars['Float']['input']>;
  storageUsedInMiB?: InputMaybe<Scalars['Float']['input']>;
  technology?: InputMaybe<Scalars['String']['input']>;
  type: DataInputElementType;
};

export type DataInputElementType = 'database' | 'server' | 'storage';

export type DataInputResponse = MutationResponse & {
  __typename: 'DataInputResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  project: Maybe<Project>;
  success: Scalars['Boolean']['output'];
};

export type DataInputStats = {
  __typename: 'DataInputStats';
  countByType: Array<InputElementTypeCount>;
  numberOfElements: Scalars['Int']['output'];
  totalCpus: Scalars['Int']['output'];
  totalRam: Scalars['Int']['output'];
};

export type DeleteCloudProviderResponse = MutationResponse & {
  __typename: 'DeleteCloudProviderResponse';
  cloudProvider: Maybe<CloudProvider>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DeleteProjectResponse = MutationResponse & {
  __typename: 'DeleteProjectResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DeleteSolutionResponse = MutationResponse & {
  __typename: 'DeleteSolutionResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Error = {
  __typename: 'Error';
  message: Maybe<Scalars['String']['output']>;
  stack: Maybe<Scalars['String']['output']>;
};

export type ExecutionInfo = {
  __typename: 'ExecutionInfo';
  all: Scalars['Int']['output'];
  created: Maybe<Scalars['Int']['output']>;
  deleted: Maybe<Scalars['Int']['output']>;
  updated: Maybe<Scalars['Int']['output']>;
};

export type ExecutionStatus = 'FAILURE' | 'REQUESTED' | 'STARTED' | 'SUCCESS';

export type ExtendedRamPriceItem = BasePriceItem & {
  __typename: 'ExtendedRamPriceItem';
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  multiplierParamKey: Scalars['String']['output'];
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type FeePriceItem = BasePriceItem & {
  __typename: 'FeePriceItem';
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type FixedStorage = BaseStorageInfo & {
  __typename: 'FixedStorage';
  maxIOPSPerInstance: Maybe<Scalars['Int']['output']>;
  maxIOPSPerVolume: Maybe<Scalars['Int']['output']>;
  maxThroughputInMbPSPerInstance: Maybe<Scalars['Int']['output']>;
  maxThroughputInMbPSPerVolume: Maybe<Scalars['Int']['output']>;
  sizeInMiB: Scalars['Int']['output'];
  storageType: Maybe<StorageType>;
};

export type FlexibleStorage = BaseStorageInfo & {
  __typename: 'FlexibleStorage';
  maxIOPSPerInstance: Maybe<Scalars['Int']['output']>;
  maxIOPSPerVolume: Maybe<Scalars['Int']['output']>;
  maxThroughputInMbPSPerInstance: Maybe<Scalars['Int']['output']>;
  maxThroughputInMbPSPerVolume: Maybe<Scalars['Int']['output']>;
  maxVolumeSizeInMiB: Maybe<Scalars['Float']['output']>;
  minVolumeSizeInMiB: Maybe<Scalars['Int']['output']>;
  storageType: Maybe<StorageType>;
};

export type FreeSubscription = BillingSubscription & {
  __typename: 'FreeSubscription';
  limits: Limits;
};

export type FreemiumSubscription = BillingSubscription & {
  __typename: 'FreemiumSubscription';
  limits: Limits;
};

export type GenericPriceItem = BasePriceItem & {
  __typename: 'GenericPriceItem';
  defaultValue: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  genericBundle: Maybe<Scalars['Int']['output']>;
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type GroupedFeePriceItem = BasePriceItem & {
  __typename: 'GroupedFeePriceItem';
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  groupedRates: Array<GroupedRates>;
  multiplierParamKey: Scalars['String']['output'];
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type GroupedRates = {
  __typename: 'GroupedRates';
  beginRange: Scalars['Float']['output'];
  endRange: Maybe<Scalars['Float']['output']>;
  pricePerUnit: Scalars['Float']['output'];
};

export type History = {
  __typename: 'History';
  author: Scalars['JSON']['output'];
  changes: Scalars['JSON']['output'];
  documentType: Scalars['String']['output'];
  elementId: Scalars['String']['output'];
  elementName: Maybe<Scalars['String']['output']>;
  eventType: HistoryEventType;
  id: Scalars['ID']['output'];
  timestamp: Scalars['Date']['output'];
};

export type HistoryConnection = {
  __typename: 'HistoryConnection';
  edges: Array<History>;
  paginationInfo: PaginationInfo;
};

export type HistoryEventType = 'Changed' | 'Created' | 'Deleted';

export type Importance = 'DONT_CARE' | 'MUST' | 'SHOULD';

export type IndustryType =
  | 'AUTOMOTIVE'
  | 'EDUCATION'
  | 'FINANCIAL_SERVICES'
  | 'GOVERNMENT_AND_PUBLIC_SECTOR'
  | 'HEALTHCARE_AND_LIFE_SCIENCES'
  | 'INDUSTRY_AGNOSTIC'
  | 'MEDIA_AND_ENTERTAINMENT';

export type InputElementTypeCount = {
  __typename: 'InputElementTypeCount';
  count: Scalars['Int']['output'];
  typeName: Scalars['String']['output'];
};

export type InstanceCarbonFootprint = {
  __typename: 'InstanceCarbonFootprint';
  co2e: Scalars['Float']['output'];
  kilowattHours: Scalars['Float']['output'];
};

export type InstanceComparisonConnection = {
  __typename: 'InstanceComparisonConnection';
  edges: Array<ProductInstance>;
  filterInfo: InstanceComparisonFilterInfo;
  paginationInfo: PaginationInfo;
};

export type InstanceComparisonFilter = {
  operatingSystem?: InputMaybe<Scalars['String']['input']>;
};

export type InstanceComparisonFilterInfo = {
  __typename: 'InstanceComparisonFilterInfo';
  operatingSystems: Maybe<Array<Scalars['String']['output']>>;
};

export type InstanceConfigType = 'gcpCompute';

export type InstanceConfiguration = {
  __typename: 'InstanceConfiguration';
  allowsExtendedRam: Scalars['Boolean']['output'];
  cpuCores: Array<Scalars['Int']['output']>;
  maxRamInMiBPerInstance: Scalars['Int']['output'];
  minRamInMiBPerInstance: Maybe<Scalars['Int']['output']>;
  ramInMiBPerCpuCore: RamInMiBPerCpuCoreConfig;
  ramIncrementInMiB: Scalars['Int']['output'];
  type: InstanceConfigType;
};

export type InstanceGeneration = {
  __typename: 'InstanceGeneration';
  baseFrequencyInGHz: Maybe<Scalars['Float']['output']>;
  codeName: Maybe<Scalars['String']['output']>;
  cpuVendor: Maybe<Scalars['String']['output']>;
  instanceGenerationIndex: Maybe<Scalars['Int']['output']>;
  processor: Scalars['String']['output'];
  processorArchitecture: Maybe<ProcessorArchitecture>;
  turboFrequencyInGHz: Maybe<Scalars['Float']['output']>;
};

export type InstanceMatchByName = {
  __typename: 'InstanceMatchByName';
  errorMessage: Maybe<Scalars['String']['output']>;
  matchType: Maybe<InstanceMatchType>;
  query: SearchQueryByName;
  result: Maybe<ProductInstance>;
};

export type InstanceMatchType = 'DEFAULT_VALUE' | 'EXACT' | 'MULTI_MATCH' | 'NO_MATCH';

export type InstancePrice = {
  __typename: 'InstancePrice';
  offers: Array<PriceOffer>;
};

export type InstanceSpecs = {
  certifications?: InputMaybe<Array<CertificationRequirement>>;
  dataCenterKey: Scalars['String']['input'];
  instanceFamily?: InputMaybe<Array<Scalars['String']['input']>>;
  minCpuCores?: InputMaybe<Scalars['Float']['input']>;
  minRamInMiB?: InputMaybe<Scalars['Float']['input']>;
  operatingSystems?: InputMaybe<Array<Scalars['String']['input']>>;
  productName: Scalars['String']['input'];
};

export type InstanceTypeConfiguration = {
  __typename: 'InstanceTypeConfiguration';
  domainModelTag: Scalars['String']['output'];
  ignoreProductPricing: Scalars['Boolean']['output'];
};

export type InstanceTypeConfigurationInput = {
  domainModelTag: Scalars['String']['input'];
  ignoreProductPricing: Scalars['Boolean']['input'];
};

export type IopsPriceItem = BasePriceItem & {
  __typename: 'IopsPriceItem';
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type LevelInfo = 'ERROR' | 'INFO' | 'WARNING';

export type LicenseType = 'BYOL' | 'LicenseIncluded';

export type Limits = {
  __typename: 'Limits';
  canAccessCertification: Scalars['Boolean']['output'];
  canAccessReservedPricing: Scalars['Boolean']['output'];
  canExportSolution: Scalars['Boolean']['output'];
  canGenAlternativeSolutions: Scalars['Boolean']['output'];
  maxProjectsPerUser: Scalars['Int']['output'];
  maxServicesPerSolution: Scalars['Int']['output'];
  maxSolutionsPerProject: Scalars['Int']['output'];
};

export type Location = TenancyInterface & {
  __typename: 'Location';
  aliasNames: Maybe<Array<Scalars['String']['output']>>;
  children: LocationChildrenConnection;
  coordinates: Maybe<Coordinates>;
  description: Maybe<Scalars['String']['output']>;
  imageUrl: Maybe<Scalars['String']['output']>;
  isInLocation: Maybe<Scalars['String']['output']>;
  isPublic: Scalars['Boolean']['output'];
  locationType: LocationType;
  locations: Array<Location>;
  locationsCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  ownedBy: Array<Scalars['String']['output']>;
  parent: Maybe<Location>;
  path: Array<Scalars['String']['output']>;
  txtureId: Scalars['String']['output'];
};

export type LocationchildrenArgs = {
  providerName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<LocationChildrenType>;
};

export type LocationChildren = DataCenter | Location;

export type LocationChildrenConnection = {
  __typename: 'LocationChildrenConnection';
  edges: Array<LocationChildren>;
  paginationInfo: PaginationInfo;
};

export type LocationChildrenType = 'DATA_CENTER' | 'LOCATION';

export type LocationCreateInput = {
  aliasNames?: InputMaybe<Array<Scalars['String']['input']>>;
  coordinates?: InputMaybe<CoordinatesInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  locationType: LocationType;
  name: Scalars['String']['input'];
  ownedBy?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LocationCreateResponse = MutationResponse & {
  __typename: 'LocationCreateResponse';
  code: Scalars['String']['output'];
  location: Maybe<Location>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type LocationDeleteResponse = MutationResponse & {
  __typename: 'LocationDeleteResponse';
  code: Scalars['String']['output'];
  location: Maybe<Location>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type LocationSearchConnection = {
  __typename: 'LocationSearchConnection';
  edges: Array<Location>;
  paginationInfo: PaginationInfo;
};

export type LocationType = 'CITY' | 'CONTINENT' | 'COUNTRY' | 'REGION';

export type LocationUpdateInput = {
  aliasNames?: InputMaybe<Array<Scalars['String']['input']>>;
  coordinates?: InputMaybe<CoordinatesInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isPublic: Scalars['Boolean']['input'];
  locationType: LocationType;
  name?: InputMaybe<Scalars['String']['input']>;
  ownedBy?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LocationUpdateResponse = MutationResponse & {
  __typename: 'LocationUpdateResponse';
  code: Scalars['String']['output'];
  location: Maybe<Location>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type MigrationTool = {
  __typename: 'MigrationTool';
  description: Maybe<Scalars['String']['output']>;
  imageUrl: Maybe<Scalars['String']['output']>;
  longDescription: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  relatedCloudProductDetails: Array<CloudProduct>;
  relatedCloudProducts: Array<Scalars['String']['output']>;
  relatedUrls: Maybe<Array<Scalars['String']['output']>>;
  txtureId: Scalars['ID']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export type MigrationToolConnection = {
  __typename: 'MigrationToolConnection';
  edges: Array<MigrationTool>;
  paginationInfo: PaginationInfo;
};

export type MigrationToolCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  providerName: Scalars['String']['input'];
  relatedCloudProducts: Array<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type MigrationToolCreateMutationResponse = MutationResponse & {
  __typename: 'MigrationToolCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  migrationTool: Maybe<MigrationTool>;
  success: Scalars['Boolean']['output'];
};

export type MigrationToolDeleteMutationResponse = MutationResponse & {
  __typename: 'MigrationToolDeleteMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  migrationTool: Maybe<MigrationTool>;
  success: Scalars['Boolean']['output'];
};

export type MigrationToolUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  providerName: Scalars['String']['input'];
  relatedCloudProducts: Array<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type MigrationToolUpdateMutationResponse = MutationResponse & {
  __typename: 'MigrationToolUpdateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  migrationTool: Maybe<MigrationTool>;
  success: Scalars['Boolean']['output'];
};

export type MultiEditChanges = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['JSON']['input']>;
  valueType: Scalars['String']['input'];
};

export type Mutation = {
  __typename: 'Mutation';
  addSolution: UpdateSolutionResponse;
  addTaxId: CompanyUpdateResponse;
  changeProdName: MutationResponse;
  clearCache: MutationResponse;
  createCertification: CertificationCreateResponse;
  createCloudProduct: CloudProductCreateResponse;
  createCloudProvider: CreateCloudProviderResponse;
  createDataCenter: DataCenterCreateResponse;
  createLocation: LocationCreateResponse;
  createMigrationTool: MigrationToolCreateMutationResponse;
  createProductAttribute: ProductAttributeCreateMutationResponse;
  createProject: ProjectCreateResponse;
  createTechnology: TechnologyCreateResponse;
  createVendor: VendorCreateMutationResponse;
  deleteCertification: CertificationDeleteResponse;
  deleteCloudProduct: CloudProductDeleteResponse;
  deleteCloudProvider: DeleteCloudProviderResponse;
  deleteDataCenter: DataCenterDeleteResponse;
  deleteLocation: LocationDeleteResponse;
  deleteMigrationTool: MigrationToolDeleteMutationResponse;
  deleteProductAttribute: ProductAttributeDeleteMutationResponse;
  deleteProject: DeleteProjectResponse;
  deleteTechnology: TechnologyDeleteResponse;
  deleteVendor: VendorDeleteMutationResponse;
  deleteVendors: MutationResponse;
  downgradeToFreemium: SubscriptionChangeResponse;
  flexibleMultiEdit: MutationResponse;
  flexibleMultiInsert: MutationResponse;
  multiEdit: MutationResponse;
  multiInsert: MutationResponse;
  processProposal: ProcessProposalResponse;
  proposeTechnologyChange: ChangeTechProposalCreateResponse;
  publishAll: PublishAllResponse;
  removeSolution: DeleteSolutionResponse;
  solutionAddProduct: UpdateSolutionResponse;
  solutionRemoveProduct: UpdateSolutionResponse;
  solutionRemoveProductInstance: UpdateSolutionResponse;
  solutionSelectPriceTerm: UpdateSolutionResponse;
  solutionUpdateProduct: UpdateSolutionResponse;
  solutionUpdateProductInstance: UpdateSolutionResponse;
  startPricingCrawler: StartCrawlerExecutionResponse;
  startTechCrawler: StartCrawlerExecutionResponse;
  subscribeFreeTrial: SubscriptionChangeResponse;
  techEditChanges: MutationResponse;
  updateCertification: CertificationUpdateResponse;
  updateCloudProduct: CloudProductUpdateResponse;
  updateCloudProvider: UpdateCloudProviderResponse;
  updateCompany: CompanyUpdateResponse;
  updateDataCenter: DataCenterUpdateResponse;
  updateDataInput: DataInputResponse;
  updateLocation: LocationUpdateResponse;
  updateMigrationTool: MigrationToolUpdateMutationResponse;
  updateProductAttribute: ProductAttributeUpdateMutationResponse;
  updateProject: ProjectUpdateResponse;
  updateProjectSolutionOrder: ProjectUpdateResponse;
  updateSolutionMeta: UpdateSolutionResponse;
  updateTechnology: TechnologyUpdateResponse;
  updateUserSettings: UpdateUserSettingsResponse;
  updateVendor: VendorUpdateMutationResponse;
  updateVendorReferences: MutationResponse;
};

export type MutationaddSolutionArgs = {
  solution: SolutionInput;
};

export type MutationaddTaxIdArgs = {
  taxId: TaxIdCreateInput;
};

export type MutationchangeProdNameArgs = {
  newProductName: Scalars['String']['input'];
  oldProductName: Scalars['String']['input'];
};

export type MutationclearCacheArgs = {
  dept: CacheDependency;
};

export type MutationcreateCertificationArgs = {
  certification: CertificationCreateInput;
};

export type MutationcreateCloudProductArgs = {
  product: CloudProductCreateInput;
};

export type MutationcreateCloudProviderArgs = {
  cloudProvider: CloudProviderCreateInput;
};

export type MutationcreateDataCenterArgs = {
  dataCenter: DataCenterCreateInput;
};

export type MutationcreateLocationArgs = {
  location: LocationCreateInput;
  parentName: Scalars['String']['input'];
};

export type MutationcreateMigrationToolArgs = {
  migrationTool: MigrationToolCreateInput;
};

export type MutationcreateProductAttributeArgs = {
  productAttribute: ProductAttributeCreateInput;
};

export type MutationcreateProjectArgs = {
  project: ProjectCreateInput;
};

export type MutationcreateTechnologyArgs = {
  technology: TechnologyCreateInput;
};

export type MutationcreateVendorArgs = {
  vendor: VendorCreateInput;
};

export type MutationdeleteCertificationArgs = {
  name: Scalars['String']['input'];
};

export type MutationdeleteCloudProductArgs = {
  name: Scalars['String']['input'];
};

export type MutationdeleteCloudProviderArgs = {
  name: Scalars['String']['input'];
};

export type MutationdeleteDataCenterArgs = {
  nameOrKey: Scalars['String']['input'];
};

export type MutationdeleteLocationArgs = {
  name: Scalars['String']['input'];
};

export type MutationdeleteMigrationToolArgs = {
  name: Scalars['String']['input'];
};

export type MutationdeleteProductAttributeArgs = {
  attributeKey: Scalars['String']['input'];
};

export type MutationdeleteProjectArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteTechnologyArgs = {
  name: Scalars['String']['input'];
};

export type MutationdeleteVendorArgs = {
  name: Scalars['String']['input'];
};

export type MutationdeleteVendorsArgs = {
  names: Array<Scalars['String']['input']>;
};

export type MutationflexibleMultiEditArgs = {
  techs?: InputMaybe<Array<TechnologyUpdateInput>>;
};

export type MutationflexibleMultiInsertArgs = {
  techs?: InputMaybe<Array<TechnologyCreateInput>>;
};

export type MutationmultiEditArgs = {
  changes: Array<MultiEditChanges>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  type: CollectionType;
};

export type MutationmultiInsertArgs = {
  changes: Array<MultiEditChanges>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  type: CollectionType;
};

export type MutationprocessProposalArgs = {
  decline?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  technology?: InputMaybe<TechnologyUpdateInput>;
};

export type MutationproposeTechnologyChangeArgs = {
  changes: TechnologyChangeInput;
  ignoreDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
  techName: Scalars['String']['input'];
};

export type MutationremoveSolutionArgs = {
  solutionId: Scalars['ID']['input'];
};

export type MutationsolutionAddProductArgs = {
  input: SolutionAddProductInput;
  solutionId: Scalars['ID']['input'];
};

export type MutationsolutionRemoveProductArgs = {
  productId: Scalars['ID']['input'];
  solutionId: Scalars['ID']['input'];
};

export type MutationsolutionRemoveProductInstanceArgs = {
  productId: Scalars['ID']['input'];
  productInstanceId: Scalars['ID']['input'];
  solutionId: Scalars['ID']['input'];
};

export type MutationsolutionSelectPriceTermArgs = {
  priceTerms: PriceTermsInput;
  solutionId: Scalars['ID']['input'];
};

export type MutationsolutionUpdateProductArgs = {
  priceParameters: Scalars['JSON']['input'];
  productId: Scalars['ID']['input'];
  solutionId: Scalars['ID']['input'];
};

export type MutationsolutionUpdateProductInstanceArgs = {
  input?: InputMaybe<SolutionUpdateProductInstanceInput>;
  productId: Scalars['ID']['input'];
  productInstanceId: Scalars['ID']['input'];
  solutionId: Scalars['ID']['input'];
};

export type MutationstartPricingCrawlerArgs = {
  name: Scalars['String']['input'];
};

export type MutationstartTechCrawlerArgs = {
  name: Scalars['String']['input'];
};

export type MutationtechEditChangesArgs = {
  changes: Array<TechEditChanges>;
};

export type MutationupdateCertificationArgs = {
  certification: CertificationUpdateInput;
  name: Scalars['String']['input'];
};

export type MutationupdateCloudProductArgs = {
  product: CloudProductUpdateInput;
};

export type MutationupdateCloudProviderArgs = {
  cloudProvider: CloudProviderUpdateInput;
};

export type MutationupdateCompanyArgs = {
  company: CompanyUpdateInput;
  id: Scalars['ID']['input'];
};

export type MutationupdateDataCenterArgs = {
  dataCenter: DataCenterUpdateInput;
  nameOrKey: Scalars['String']['input'];
};

export type MutationupdateDataInputArgs = {
  dataInputElements: Array<DataInputElementInput>;
  projectId: Scalars['ID']['input'];
};

export type MutationupdateLocationArgs = {
  location: LocationUpdateInput;
  name: Scalars['String']['input'];
};

export type MutationupdateMigrationToolArgs = {
  migrationTool: MigrationToolUpdateInput;
  name: Scalars['String']['input'];
};

export type MutationupdateProductAttributeArgs = {
  attributeKey: Scalars['String']['input'];
  productAttribute: ProductAttributeUpdateInput;
};

export type MutationupdateProjectArgs = {
  id: Scalars['ID']['input'];
  project: ProjectUpdateInput;
};

export type MutationupdateProjectSolutionOrderArgs = {
  id: Scalars['ID']['input'];
  solutionRankUpdate: Array<SolutionRankUpdateInput>;
};

export type MutationupdateSolutionMetaArgs = {
  solution: SolutionMetaInput;
};

export type MutationupdateTechnologyArgs = {
  name: Scalars['String']['input'];
  technology: TechnologyUpdateInput;
};

export type MutationupdateUserSettingsArgs = {
  settings: UserSettingsInput;
};

export type MutationupdateVendorArgs = {
  name: Scalars['String']['input'];
  vendor: VendorUpdateInput;
};

export type MutationupdateVendorReferencesArgs = {
  newVendorName: Scalars['String']['input'];
  oldVendorName: Scalars['String']['input'];
};

export type MutationResponse = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type OfferPrice = {
  __typename: 'OfferPrice';
  offerTerms: PriceTerms;
  prices: ComputedPrice;
};

export type OfferingClass = 'convertible' | 'standard';

export type OffsetPagination = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type OperationPriceItem = BasePriceItem & {
  __typename: 'OperationPriceItem';
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  operationBundle: Maybe<Scalars['Int']['output']>;
  operationType: Array<OperationType>;
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type OperationType = 'READ' | 'WRITE' | 'unspecified';

export type PaginatedTechnologies = {
  __typename: 'PaginatedTechnologies';
  edges: Array<Technology>;
  paginationInfo: PaginationInfo;
};

export type PaginationInfo = {
  __typename: 'PaginationInfo';
  totalCount: Scalars['Int']['output'];
};

export type PaySubscription = BillingSubscription & {
  __typename: 'PaySubscription';
  limits: Limits;
  subscriptionId: Scalars['String']['output'];
};

export type PriceEstimateParams = {
  monthlyRuntimeInH?: InputMaybe<Scalars['Float']['input']>;
  storageInGiBy?: InputMaybe<Scalars['Float']['input']>;
  terms?: InputMaybe<PriceTermsInput>;
};

export type PriceHistory = {
  __typename: 'PriceHistory';
  offers: Array<OfferPrice>;
  timestamp: Scalars['Date']['output'];
};

export type PriceItemType =
  | 'backup'
  | 'burstingFee'
  | 'connections'
  | 'core'
  | 'extendedRam'
  | 'fee'
  | 'generic'
  | 'groupedFee'
  | 'iops'
  | 'operation'
  | 'ram'
  | 'requests'
  | 'rule'
  | 'runtime'
  | 'storage'
  | 'traffic'
  | 'unknown';

export type PriceOffer = {
  __typename: 'PriceOffer';
  priceItems: Array<BasePriceItem>;
  sku: Maybe<Scalars['String']['output']>;
  terms: PriceTerms;
};

export type PriceTermType =
  | 'Free'
  | 'OnDemand'
  | 'OneTime'
  | 'OracleMonthlyFlex'
  | 'Reserved'
  | 'Spot'
  | 'SpotReserved';

export type PriceTerms = {
  __typename: 'PriceTerms';
  leaseContractLength: Maybe<Scalars['String']['output']>;
  licenseType: Maybe<LicenseType>;
  offeringClass: Maybe<OfferingClass>;
  purchaseOption: Maybe<Scalars['String']['output']>;
  type: PriceTermType;
};

export type PriceTermsInput = {
  leaseContractLength?: InputMaybe<Scalars['String']['input']>;
  licenseType?: InputMaybe<LicenseType>;
  offeringClass?: InputMaybe<OfferingClass>;
  purchaseOption?: InputMaybe<Scalars['String']['input']>;
  type: PriceTermType;
};

export type PricingCrawler = {
  __typename: 'PricingCrawler';
  executions: Array<CrawlerExecution>;
  latestExecution: Maybe<CrawlerExecution>;
  name: Scalars['String']['output'];
};

export type ProcessProposalResponse = MutationResponse & {
  __typename: 'ProcessProposalResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  proposal: Maybe<ChangeTechProposal>;
  success: Scalars['Boolean']['output'];
  technology: Technology;
};

export type ProcessorArchitecture = 'amd64' | 'arm64';

export type ProdAttribute = {
  __typename: 'ProdAttribute';
  attributeKey: Scalars['String']['output'];
  sourceInfo: Maybe<SourceInfo>;
  value: Scalars['JSON']['output'];
  valueType: ValueType;
};

export type ProdAttributeInput = {
  attributeKey: Scalars['String']['input'];
  sourceInfo?: InputMaybe<SourceInfoInput>;
  value: Scalars['JSON']['input'];
  valueType: ValueType;
};

export type ProductAttribute = {
  __typename: 'ProductAttribute';
  attributeKey: Scalars['String']['output'];
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  productCategory: Maybe<Array<Scalars['String']['output']>>;
  txtureId: Scalars['ID']['output'];
  valueType: ValueType;
};

export type ProductAttributeConnection = {
  __typename: 'ProductAttributeConnection';
  edges: Array<ProductAttribute>;
  paginationInfo: PaginationInfo;
};

export type ProductAttributeCreateInput = {
  attributeKey: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  productCategory?: InputMaybe<Array<Scalars['String']['input']>>;
  valueType: ValueType;
};

export type ProductAttributeCreateMutationResponse = MutationResponse & {
  __typename: 'ProductAttributeCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  productAttribute: Maybe<ProductAttribute>;
  success: Scalars['Boolean']['output'];
};

export type ProductAttributeDeleteMutationResponse = MutationResponse & {
  __typename: 'ProductAttributeDeleteMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  productAttribute: Maybe<ProductAttribute>;
  success: Scalars['Boolean']['output'];
};

export type ProductAttributeUpdateInput = {
  attributeKey: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  productCategory?: InputMaybe<Array<Scalars['String']['input']>>;
  valueType: ValueType;
};

export type ProductAttributeUpdateMutationResponse = MutationResponse & {
  __typename: 'ProductAttributeUpdateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  productAttribute: Maybe<ProductAttribute>;
  success: Scalars['Boolean']['output'];
};

export type ProductCertification = {
  __typename: 'ProductCertification';
  certificates: Array<Certification>;
  dataCenter: Maybe<DataCenter>;
  location: Scalars['String']['output'];
};

export type ProductConnection = {
  __typename: 'ProductConnection';
  edges: Array<CloudProduct>;
  paginationInfo: PaginationInfo;
};

export type ProductGroupInfo = {
  __typename: 'ProductGroupInfo';
  productGroup: Scalars['String']['output'];
  products: Array<CloudProduct>;
};

export type ProductInstance = TenancyInterface & {
  __typename: 'ProductInstance';
  baselineUtilizationPerCPU: Maybe<Scalars['Float']['output']>;
  carbonFootprint: Maybe<InstanceCarbonFootprint>;
  certifications: Maybe<Array<Scalars['String']['output']>>;
  connectedLocations: Maybe<Array<Scalars['String']['output']>>;
  cpuCores: Maybe<Scalars['Float']['output']>;
  dataCenter: DataCenter;
  dataCenterKey: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  disk: Maybe<Array<BaseStorageInfo>>;
  estimatedPrice: Scalars['Float']['output'];
  generation: Maybe<Array<InstanceGeneration>>;
  gpusPerVm: Maybe<Scalars['Float']['output']>;
  hasLowPreference: Maybe<Scalars['Boolean']['output']>;
  hasReservedPricing: Scalars['Boolean']['output'];
  hasTerraformSupport: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  instanceConfiguration: Maybe<InstanceConfiguration>;
  instanceFamily: Maybe<Scalars['String']['output']>;
  instancePrice: Maybe<ComputedInstancePrice>;
  instanceReplacements: Array<ProductInstance>;
  instanceType: Maybe<Scalars['String']['output']>;
  instancesInOtherRegions: InstanceComparisonConnection;
  isBurstable: Maybe<Scalars['Boolean']['output']>;
  isHighAvailable: Maybe<Scalars['Boolean']['output']>;
  isPublic: Scalars['Boolean']['output'];
  license: Maybe<Scalars['String']['output']>;
  loadBalancerType: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  networkPerformance: Maybe<Scalars['String']['output']>;
  numberOfReplicaNodes: Maybe<Scalars['Int']['output']>;
  operatingSystem: Maybe<Scalars['String']['output']>;
  ownedBy: Array<Scalars['String']['output']>;
  priceHistory: Array<PriceHistory>;
  priceInfo: InstancePrice;
  product: CloudProduct;
  productName: Scalars['String']['output'];
  providerKey: Scalars['String']['output'];
  ramInMiB: Maybe<Scalars['Float']['output']>;
  routingOptions: Maybe<RoutingOptions>;
  sapCertifications: Maybe<Array<Scalars['String']['output']>>;
  storageInfo: Maybe<StorageInfo>;
  terraformScript: Maybe<Scalars['String']['output']>;
  terraformTemplate: Maybe<Scalars['String']['output']>;
  totalInstanceStorageInMiB: Maybe<Scalars['Int']['output']>;
  trafficType: Maybe<TrafficType>;
  txtureId: Scalars['String']['output'];
};

export type ProductInstancecarbonFootprintArgs = {
  priceItemParameters?: InputMaybe<Scalars['JSON']['input']>;
};

export type ProductInstanceinstancePriceArgs = {
  estimateParams?: InputMaybe<PriceEstimateParams>;
};

export type ProductInstanceinstancesInOtherRegionsArgs = {
  filter?: InputMaybe<InstanceComparisonFilter>;
  pagination?: InputMaybe<OffsetPagination>;
};

export type ProductInstancepriceHistoryArgs = {
  endTimeStamp?: InputMaybe<Scalars['Int']['input']>;
  priceItemParameters?: InputMaybe<Scalars['JSON']['input']>;
  startTimeStamp?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductInstanceCertification = {
  __typename: 'ProductInstanceCertification';
  description: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type ProductInstanceConfiguration = {
  __typename: 'ProductInstanceConfiguration';
  carbonFootprint: Maybe<CarbonFootprint>;
  dataCenterId: Scalars['ID']['output'];
  instanceId: Scalars['String']['output'];
  priceItemParameters: Scalars['JSON']['output'];
  priceTerms: PriceTerms;
  quantity: Scalars['Int']['output'];
};

export type ProductInstanceConfigurationInput = {
  dataCenterId: Scalars['ID']['input'];
  instanceId: Scalars['String']['input'];
  priceItemParameters: Scalars['JSON']['input'];
  priceTerms: PriceTermsInput;
  quantity: Scalars['Int']['input'];
};

export type ProductInstanceConnection = {
  __typename: 'ProductInstanceConnection';
  edges: Array<ProductInstance>;
  filterInfo: ProductInstanceFilterInfo;
  paginationInfo: PaginationInfo;
};

export type ProductInstanceFilter = {
  accessTimeInMs?: InputMaybe<Scalars['Int']['input']>;
  accessType?: InputMaybe<Scalars['String']['input']>;
  cpuCores?: InputMaybe<RangeInput>;
  dataCenterKey?: InputMaybe<Scalars['String']['input']>;
  instanceFamily?: InputMaybe<Scalars['String']['input']>;
  instanceType?: InputMaybe<Scalars['String']['input']>;
  isHighAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  minimumStorageDurationInDays?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  operatingSystem?: InputMaybe<Scalars['String']['input']>;
  ramInMiB?: InputMaybe<RangeInput>;
  redundancy?: InputMaybe<Scalars['String']['input']>;
  routingOptions?: InputMaybe<RoutingOptions>;
  sapCertifications?: InputMaybe<Scalars['String']['input']>;
  storageRedundancy?: InputMaybe<Scalars['String']['input']>;
  storageType?: InputMaybe<Scalars['String']['input']>;
  totalInstanceStorageInMiB?: InputMaybe<RangeInput>;
  trafficType?: InputMaybe<TrafficType>;
};

export type ProductInstanceFilterInfo = {
  __typename: 'ProductInstanceFilterInfo';
  accessTimeInMs: Maybe<Array<Scalars['Int']['output']>>;
  accessType: Maybe<Array<Scalars['String']['output']>>;
  cpuCores: Maybe<Range>;
  dataCenters: Maybe<Array<DataCenter>>;
  instanceFamily: Maybe<Array<Scalars['String']['output']>>;
  instanceType: Maybe<Array<Scalars['String']['output']>>;
  isHighAvailable: Maybe<Array<Scalars['Boolean']['output']>>;
  license: Maybe<Array<Scalars['String']['output']>>;
  minimumStorageDurationInDays: Maybe<Array<Scalars['Int']['output']>>;
  operatingSystems: Maybe<Array<Scalars['String']['output']>>;
  ramInMiB: Maybe<Range>;
  redundancy: Maybe<Array<Scalars['String']['output']>>;
  routingOptions: Maybe<Array<RoutingOptions>>;
  sapCertifications: Maybe<Array<Scalars['String']['output']>>;
  storageRedundancy: Maybe<Array<Scalars['String']['output']>>;
  storageType: Maybe<Array<Scalars['String']['output']>>;
  totalCount: Scalars['Int']['output'];
  totalInstanceStorageInMiB: Maybe<Range>;
  trafficType: Maybe<Array<TrafficType>>;
};

export type ProductInstanceSortOption = {
  direction: SortDirection;
  propertyName: Scalars['String']['input'];
};

export type ProductLandingZone = 'PrivateCloud' | 'PublicCloud';

export type ProductSearchResultNode = {
  __typename: 'ProductSearchResultNode';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  providerName: Scalars['String']['output'];
  type: TechType;
};

export type ProductStatistics = {
  __typename: 'ProductStatistics';
  cheapestRegions: Array<Scalars['String']['output']>;
  cpuCores: Maybe<Range>;
  numberOfInstanceTypes: Scalars['Int']['output'];
  operatingSystems: Array<Scalars['String']['output']>;
  ramInMiB: Maybe<Range>;
  tendingCheaperRegions: Array<Scalars['String']['output']>;
};

export type Project = {
  __typename: 'Project';
  dataInput: Maybe<DataInput>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: User;
  proposals: Array<Solution>;
  solutions: Array<Solution>;
};

export type ProjectproposalsArgs = {
  preferences?: InputMaybe<Scalars['JSON']['input']>;
};

export type ProjectCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ProjectCreateResponse = MutationResponse & {
  __typename: 'ProjectCreateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  project: Maybe<Project>;
  success: Scalars['Boolean']['output'];
};

export type ProjectUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectUpdateResponse = MutationResponse & {
  __typename: 'ProjectUpdateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  project: Maybe<Project>;
  success: Scalars['Boolean']['output'];
};

export type ProviderCertification = {
  __typename: 'ProviderCertification';
  global: Array<Certification>;
  other: Array<Certification>;
};

export type ProviderType = 'GENERAL' | 'SPECIFIC';

export type PublishAllResponse = MutationResponse & {
  __typename: 'PublishAllResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type PublishLog = {
  __typename: 'PublishLog';
  durationInSeconds: Maybe<Scalars['Float']['output']>;
  message: Maybe<Scalars['String']['output']>;
  requestUser: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  status: PublishLogStatus;
  type: PublishLogType;
};

export type PublishLogStatus = 'FAILURE' | 'STARTED' | 'SUCCESS';

export type PublishLogType = 'DocumentHistory' | 'ProductInstances' | 'TaxonomyData' | 'TaxonomyTreeCSV';

export type Query = {
  __typename: 'Query';
  billingConfig: BillingConfig;
  canAddProject: Scalars['Boolean']['output'];
  canAddSolution: Scalars['Boolean']['output'];
  canAddSolutionService: Scalars['Boolean']['output'];
  certification: Maybe<Certification>;
  cloudProduct: Maybe<CloudProduct>;
  cloudProductReplacements: Array<CloudProductReplacement>;
  cloudProducts: Array<CloudProduct>;
  cloudProductsByName: Array<CloudProduct>;
  cloudProductsWithoutAttributes: ProductConnection;
  cloudProvider: Maybe<CloudProvider>;
  cloudProviderById: Maybe<CloudProvider>;
  cloudProviderStats: CloudProviderStats;
  cloudProviders: Array<CloudProvider>;
  cloudProvidersById: Array<CloudProvider>;
  cloudSearch: CloudSearchResult;
  dataCenter: Maybe<DataCenter>;
  dataCenterById: Maybe<DataCenter>;
  dataCenters: Array<DataCenter>;
  dataCentersById: Array<DataCenter>;
  dataCentersByProvider: Array<DataCenter>;
  findInstance: Maybe<ProductInstance>;
  findProductsFromProvider: Array<CloudProduct>;
  findVendor: Array<Vendor>;
  generateTfScriptFromInstances: Array<TerraformScriptInfo>;
  getChangeCountPerUser: Array<UserChangeCount>;
  getDataCountOverTime: Array<CollectionChangeStats>;
  getDataCountTotal: Array<DataCountTotal>;
  getHistoryByElementId: HistoryConnection;
  getLatestCrawlerUpdateTime: Maybe<Scalars['Date']['output']>;
  getPriceHistory: Array<PriceHistory>;
  getPublishLogs: Array<PublishLog>;
  getTaxonomyChanges: HistoryConnection;
  location: Maybe<Location>;
  locationSearch: LocationSearchConnection;
  /** @deprecated Field no longer supported */
  locations: Array<Location>;
  locationsById: Array<Location>;
  me: User;
  migrationTool: Maybe<MigrationTool>;
  migrationTools: MigrationToolConnection;
  migrationToolsByProduct: Array<MigrationTool>;
  paginatedCertifications: CertificationConnection;
  plannedDataCenters: Array<DataCenter>;
  pricingCrawlers: Array<PricingCrawler>;
  privateTechnologies: Array<Technology>;
  productAttribute: Maybe<ProductAttribute>;
  productAttributes: ProductAttributeConnection;
  productCategories: Array<Technology>;
  productGroupInfo: Array<ProductGroupInfo>;
  productInstance: Maybe<ProductInstance>;
  productInstanceById: Maybe<ProductInstance>;
  productInstanceCertifications: Array<ProductInstanceCertification>;
  productInstances: Array<ProductInstance>;
  productInstancesByName: Array<InstanceMatchByName>;
  project: Maybe<Project>;
  regionSearch: Array<LocationChildren>;
  searchByTxtureId: Maybe<Scalars['JSON']['output']>;
  solution: Maybe<Solution>;
  taxTracking: Array<TaxTrackingEntry>;
  techCrawlerStat: Maybe<TechCrawlerStat>;
  techCrawlerStats: Array<TechCrawlerStat>;
  techCrawlers: Maybe<Array<TechCrawler>>;
  techSearchSuggestions: TaxonomySearchSuggestions;
  technologies: Maybe<Array<Technology>>;
  technologiesByContainerImages: Array<TechnologyByContainerImage>;
  technologiesWithLicenseOptions: Array<Technology>;
  technology: Maybe<Technology>;
  technologyByDomainTag: Technology;
  technologyProposals: ChangeTechProposalConnection;
  technologyTermSearch: Array<Maybe<TechnologyTermSearchResult>>;
  unusedVendors: Array<Scalars['String']['output']>;
  validationIssues: Array<ValidationIssue>;
  vendor: Maybe<Vendor>;
  vendorTechs: PaginatedTechnologies;
  vendors: VendorConnection;
};

export type QuerycanAddSolutionArgs = {
  projectId: Scalars['ID']['input'];
};

export type QuerycanAddSolutionServiceArgs = {
  solutionId: Scalars['ID']['input'];
};

export type QuerycertificationArgs = {
  name: Scalars['String']['input'];
};

export type QuerycloudProductArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  txtureId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerycloudProductReplacementsArgs = {
  maxResults?: InputMaybe<Scalars['Int']['input']>;
  stepOverCategories?: InputMaybe<Scalars['Boolean']['input']>;
  technologyName: Scalars['String']['input'];
};

export type QuerycloudProductsArgs = {
  txtureIds: Array<Scalars['String']['input']>;
};

export type QuerycloudProductsByNameArgs = {
  names: Array<Scalars['String']['input']>;
};

export type QuerycloudProviderArgs = {
  name: Scalars['String']['input'];
};

export type QuerycloudProviderByIdArgs = {
  id: Scalars['String']['input'];
};

export type QuerycloudProvidersArgs = {
  filter?: InputMaybe<CloudProvidersFilterInput>;
};

export type QuerycloudProvidersByIdArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QuerycloudSearchArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchString: Scalars['String']['input'];
};

export type QuerydataCenterArgs = {
  nameOrKey: Scalars['String']['input'];
};

export type QuerydataCenterByIdArgs = {
  dataCenterId: Scalars['ID']['input'];
};

export type QuerydataCentersByIdArgs = {
  dataCenterIds: Array<Scalars['ID']['input']>;
};

export type QuerydataCentersByProviderArgs = {
  providerNames: Array<Scalars['String']['input']>;
};

export type QueryfindInstanceArgs = {
  specs: InstanceSpecs;
};

export type QueryfindProductsFromProviderArgs = {
  prefix: Scalars['String']['input'];
  providerName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryfindVendorArgs = {
  prefix: Scalars['String']['input'];
};

export type QuerygenerateTfScriptFromInstancesArgs = {
  instanceInfo: Array<TerraformInstanceInfo>;
};

export type QuerygetDataCountOverTimeArgs = {
  endTimeStamp?: InputMaybe<Scalars['Int']['input']>;
  startTimeStamp?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerygetHistoryByElementIdArgs = {
  elementId: Scalars['ID']['input'];
  pagination?: InputMaybe<OffsetPagination>;
};

export type QuerygetLatestCrawlerUpdateTimeArgs = {
  productName: Scalars['String']['input'];
};

export type QuerygetPriceHistoryArgs = {
  endTimeStamp?: InputMaybe<Scalars['Date']['input']>;
  priceItemParameters?: InputMaybe<Scalars['JSON']['input']>;
  startTimeStamp?: InputMaybe<Scalars['Date']['input']>;
  txtureId: Scalars['String']['input'];
};

export type QuerygetTaxonomyChangesArgs = {
  documentType?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<OffsetPagination>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type QuerylocationArgs = {
  name: Scalars['String']['input'];
};

export type QuerylocationSearchArgs = {
  pagination?: InputMaybe<OffsetPagination>;
  query: Scalars['String']['input'];
};

export type QuerylocationsByIdArgs = {
  locationIds: Array<Scalars['ID']['input']>;
};

export type QuerymigrationToolArgs = {
  name: Scalars['String']['input'];
};

export type QuerymigrationToolsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<OffsetPagination>;
  providerName?: InputMaybe<Scalars['String']['input']>;
};

export type QuerymigrationToolsByProductArgs = {
  productName: Scalars['String']['input'];
};

export type QuerypaginatedCertificationsArgs = {
  filter?: InputMaybe<CertificationFilterInput>;
  pagination?: InputMaybe<OffsetPagination>;
};

export type QuerypricingCrawlersArgs = {
  searchName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryproductAttributeArgs = {
  attributeKey: Scalars['String']['input'];
};

export type QueryproductAttributesArgs = {
  attributeKey?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<OffsetPagination>;
};

export type QueryproductInstanceArgs = {
  id: Scalars['String']['input'];
};

export type QueryproductInstanceByIdArgs = {
  txtureId: Scalars['String']['input'];
};

export type QueryproductInstancesArgs = {
  txtureIds: Array<Scalars['String']['input']>;
};

export type QueryproductInstancesByNameArgs = {
  searchInput: Array<SearchQueryByNameInput>;
};

export type QueryprojectArgs = {
  id: Scalars['ID']['input'];
};

export type QueryregionSearchArgs = {
  query: Scalars['String']['input'];
};

export type QuerysearchByTxtureIdArgs = {
  txtureId: Scalars['String']['input'];
};

export type QuerysolutionArgs = {
  solutionId: Scalars['ID']['input'];
};

export type QuerytechCrawlerStatArgs = {
  name: Scalars['String']['input'];
};

export type QuerytechCrawlersArgs = {
  searchName?: InputMaybe<Scalars['String']['input']>;
};

export type QuerytechSearchSuggestionsArgs = {
  domainTagRestriction?: InputMaybe<Scalars['String']['input']>;
  searchName: Scalars['String']['input'];
};

export type QuerytechnologiesArgs = {
  names: Array<Scalars['String']['input']>;
};

export type QuerytechnologiesByContainerImagesArgs = {
  images: Array<Scalars['String']['input']>;
};

export type QuerytechnologyArgs = {
  name: Scalars['String']['input'];
};

export type QuerytechnologyByDomainTagArgs = {
  domainTag?: InputMaybe<Scalars['String']['input']>;
};

export type QuerytechnologyProposalsArgs = {
  crawlerName: Scalars['String']['input'];
  pagination?: InputMaybe<OffsetPagination>;
  searchName?: InputMaybe<Scalars['String']['input']>;
  statusFilter?: InputMaybe<Array<ChangeTechProcessedType>>;
  typeFilter?: InputMaybe<Array<ChangeTechProposalType>>;
};

export type QuerytechnologyTermSearchArgs = {
  names: Array<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type QueryvendorArgs = {
  name: Scalars['String']['input'];
};

export type QueryvendorTechsArgs = {
  pagination?: InputMaybe<OffsetPagination>;
  techFilterOption?: InputMaybe<VendorTechFilterOptions>;
  techNameFilter?: InputMaybe<Scalars['String']['input']>;
  vendorName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryvendorsArgs = {
  pagination?: InputMaybe<OffsetPagination>;
  searchName?: InputMaybe<Scalars['String']['input']>;
};

export type RamInMiBPerCpuCoreConfig = {
  __typename: 'RamInMiBPerCpuCoreConfig';
  max: Scalars['Float']['output'];
  maxInclusive: Scalars['Boolean']['output'];
  min: Scalars['Float']['output'];
  minInclusive: Scalars['Boolean']['output'];
};

export type RamPriceItem = BasePriceItem & {
  __typename: 'RamPriceItem';
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  multiplierParamKey: Scalars['String']['output'];
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type Range = {
  __typename: 'Range';
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
};

export type RangeInput = {
  max: Scalars['Float']['input'];
  min: Scalars['Float']['input'];
};

export type Redundancy = 'GRS' | 'GZRS' | 'LRS' | 'ZRS';

export type RequestPriceItem = BasePriceItem & {
  __typename: 'RequestPriceItem';
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  parameterKey: Scalars['String']['output'];
  requestBundle: Maybe<Scalars['Int']['output']>;
  requestType: Array<RequestType>;
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type RequestType = 'ALL' | 'COPY' | 'DELETE' | 'GET' | 'LIST' | 'POST' | 'PUT';

export type RoutingOptions = 'ProviderNetwork' | 'PublicInternet';

export type RulePriceItem = BasePriceItem & {
  __typename: 'RulePriceItem';
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type RuntimePriceItem = BasePriceItem & {
  __typename: 'RuntimePriceItem';
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type SearchQueryByName = {
  __typename: 'SearchQueryByName';
  accessType: Maybe<Scalars['String']['output']>;
  cpuCores: Maybe<Scalars['Float']['output']>;
  dataCenterName: Maybe<Scalars['String']['output']>;
  isHighAvailable: Maybe<Scalars['Boolean']['output']>;
  license: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  operatingSystem: Maybe<Scalars['String']['output']>;
  productName: Maybe<Scalars['String']['output']>;
  providerName: Maybe<Scalars['String']['output']>;
  ramInMiB: Maybe<Scalars['Float']['output']>;
  storageRedundancy: Maybe<Scalars['String']['output']>;
};

export type SearchQueryByNameInput = {
  accessType?: InputMaybe<Scalars['String']['input']>;
  cpuCores?: InputMaybe<Scalars['Float']['input']>;
  dataCenterName?: InputMaybe<Scalars['String']['input']>;
  isHighAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  license?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  operatingSystem?: InputMaybe<Scalars['String']['input']>;
  productName?: InputMaybe<Scalars['String']['input']>;
  providerName?: InputMaybe<Scalars['String']['input']>;
  ramInMiB?: InputMaybe<Scalars['Float']['input']>;
  storageRedundancy?: InputMaybe<Scalars['String']['input']>;
};

export type Solution = {
  __typename: 'Solution';
  alternativeSolution: AlternativeSolutionResp;
  carbonFootprint: Maybe<CarbonFootprint>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items: Array<SolutionItem>;
  name: Scalars['String']['output'];
  priceTerms: PriceTerms;
  rank: Scalars['Int']['output'];
  totalPrice: ComputedPrice;
};

export type SolutionalternativeSolutionArgs = {
  alternativeLocation?: InputMaybe<Scalars['ID']['input']>;
  alternativeProvider?: InputMaybe<Scalars['ID']['input']>;
};

export type SolutionAddProductInput = {
  priceParameters?: InputMaybe<Scalars['JSON']['input']>;
  priceTerms?: InputMaybe<PriceTermsInput>;
  productId: Scalars['ID']['input'];
  productInstanceId?: InputMaybe<Scalars['ID']['input']>;
};

export type SolutionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  items: Array<SolutionItemInput>;
  name: Scalars['String']['input'];
  priceTerms: PriceTermsInput;
  projectId: Scalars['ID']['input'];
  rank?: InputMaybe<Scalars['Int']['input']>;
  totalPrice: ComputedPriceInput;
};

export type SolutionItem = {
  __typename: 'SolutionItem';
  cloudProductId: Scalars['String']['output'];
  priceItemParameters: Scalars['JSON']['output'];
  productInstances: Array<ProductInstanceConfiguration>;
  providerId: Scalars['ID']['output'];
};

export type SolutionItemInput = {
  cloudProductId: Scalars['String']['input'];
  priceItemParameters: Scalars['JSON']['input'];
  productInstances: Array<ProductInstanceConfigurationInput>;
  providerId: Scalars['ID']['input'];
};

export type SolutionMetaInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type SolutionRankUpdateInput = {
  rank: Scalars['Int']['input'];
  solutionId: Scalars['ID']['input'];
};

export type SolutionUpdateProductInstanceInput = {
  priceItemParameters: Scalars['JSON']['input'];
  priceTerms: PriceTermsInput;
  quantity: Scalars['Int']['input'];
};

export type SortDirection = 'asc' | 'desc';

export type SourceInfo = {
  __typename: 'SourceInfo';
  key: Scalars['String']['output'];
  note: Maybe<Scalars['String']['output']>;
  sourceUrl: Scalars['String']['output'];
};

export type SourceInfoInput = {
  key: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  sourceUrl: Scalars['String']['input'];
};

export type StartCrawlerExecutionResponse = MutationResponse & {
  __typename: 'StartCrawlerExecutionResponse';
  code: Scalars['String']['output'];
  crawler: Maybe<PricingCrawler>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type StorageAccessType = 'Archive' | 'Cold' | 'Hot' | 'Warm';

export type StorageInfo = {
  __typename: 'StorageInfo';
  accessTimeInMs: Scalars['Int']['output'];
  accessType: StorageAccessType;
  minimumStorageDurationInDays: Scalars['Int']['output'];
  redundancy: Redundancy;
  storageRedundancy: StorageRedundancy;
  vendorAccessType: Maybe<Scalars['String']['output']>;
  vendorStorageRedundancy: Maybe<Scalars['String']['output']>;
};

export type StoragePriceItem = BasePriceItem & {
  __typename: 'StoragePriceItem';
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type StorageRedundancy = 'GLOBAL' | 'GLOBAL_REGION' | 'LOCAL' | 'REGION';

export type StorageType = 'HDD' | 'NVMe_SSD' | 'SAN' | 'SSD';

export type SubscriptionChangeResponse = MutationResponse & {
  __typename: 'SubscriptionChangeResponse';
  code: Scalars['String']['output'];
  company: Maybe<Company>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type TaxIdCreateInput = {
  type: TaxIdType;
  value: Scalars['String']['input'];
};

export type TaxIdType = 'eu_vat' | 'us_ein';

export type TaxTrackingEntry = {
  __typename: 'TaxTrackingEntry';
  customerNames: Array<Scalars['String']['output']>;
  isKnownTechnology: Scalars['Boolean']['output'];
  lastReportedTimestamp: Scalars['Date']['output'];
  path: Maybe<Array<Scalars['String']['output']>>;
  searchNames: Maybe<Array<Scalars['String']['output']>>;
  technologyName: Scalars['String']['output'];
  totalUsage: Scalars['Int']['output'];
  type: TaxonomyTrackingEntryType;
};

export type TaxonomyID = {
  idType: TechID;
  name: Scalars['String']['output'];
  txtureId: Scalars['String']['output'];
};

export type TaxonomySearchResultNode = ProductSearchResultNode | TechnologySearchResultNode;

export type TaxonomySearchSuggestions = {
  __typename: 'TaxonomySearchSuggestions';
  domainRestrictionTechName: Scalars['String']['output'];
  suggestions: Array<TaxonomySearchResultNode>;
};

export type TaxonomyTrackingEntryType = 'CloudProduct' | 'Technology' | 'Unknown';

export type TechCrawler = {
  __typename: 'TechCrawler';
  name: Scalars['String']['output'];
};

export type TechCrawlerStat = {
  __typename: 'TechCrawlerStat';
  crawlerName: Scalars['String']['output'];
  executions: Array<CrawlerExecution>;
  latestExecution: Maybe<CrawlerExecution>;
  numberOfProposals: Scalars['Int']['output'];
};

export type TechEditChanges = {
  newName: Scalars['String']['input'];
  oldName: Scalars['String']['input'];
};

export type TechID = 'CloudProduct' | 'Technology';

export type TechType = 'CloudProduct' | 'Technology';

export type Technology = TenancyInterface & {
  __typename: 'Technology';
  aliasNames: Array<Scalars['String']['output']>;
  allowBYOLOption: Maybe<Scalars['Boolean']['output']>;
  architectures: Array<Architecture>;
  category: Array<Scalars['String']['output']>;
  categoryName: Maybe<Scalars['String']['output']>;
  childSimilarity: Maybe<Scalars['Float']['output']>;
  children: TechnologyChildrenConnection;
  cloudOnly: Maybe<Scalars['Boolean']['output']>;
  cloudProductReplacements: Array<CloudProductReplacement>;
  cloudReadiness: Maybe<Scalars['Float']['output']>;
  cveIds: Maybe<Array<Scalars['String']['output']>>;
  description: Maybe<Scalars['String']['output']>;
  dockerImage: Maybe<Scalars['String']['output']>;
  domainModelTag: Maybe<Scalars['String']['output']>;
  eol: Maybe<Scalars['Date']['output']>;
  eolExtendedSupport: Maybe<Scalars['Date']['output']>;
  imageUrl: Maybe<Scalars['String']['output']>;
  isEncrypted: Maybe<Scalars['Boolean']['output']>;
  isInhouseTechnology: Maybe<Scalars['Boolean']['output']>;
  isOpenSource: Maybe<Scalars['Boolean']['output']>;
  isPublic: Scalars['Boolean']['output'];
  isRepositoryIrrelevant: Maybe<Scalars['Boolean']['output']>;
  license: Maybe<Scalars['String']['output']>;
  licenseOptions: Array<TechnologyLicenseOption>;
  name: Scalars['String']['output'];
  nearestDomainModelTag: Maybe<Scalars['String']['output']>;
  nearestNonVersionTechnology: Maybe<Technology>;
  numberOfCve: Maybe<Scalars['Int']['output']>;
  operatingSystems: Array<Scalars['String']['output']>;
  ownedBy: Array<Scalars['String']['output']>;
  parent: Maybe<Technology>;
  parentName: Maybe<Scalars['String']['output']>;
  path: Array<Technology>;
  portNumbers: Maybe<Array<Scalars['Int']['output']>>;
  providedBy: TechnologyProvidedByConnection;
  repositoryUrl: Maybe<Scalars['String']['output']>;
  technologyType: Maybe<TechnologyType>;
  txtureId: Scalars['String']['output'];
  type: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
  vendorInfo: Maybe<Vendor>;
  vendorName: Maybe<Scalars['String']['output']>;
  version: Maybe<Version>;
  wikipediaBaseId: Maybe<Scalars['String']['output']>;
};

export type TechnologycloudProductReplacementsArgs = {
  maxResults?: InputMaybe<Scalars['Int']['input']>;
  stepOverCategories?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TechnologyByContainerImage = {
  __typename: 'TechnologyByContainerImage';
  originalName: Scalars['String']['output'];
  resolvedTechnology: Maybe<Technology>;
};

export type TechnologyChangeInput = {
  aliasNames?: InputMaybe<Array<Scalars['String']['input']>>;
  allowBYOLOption?: InputMaybe<Scalars['Boolean']['input']>;
  architectures?: InputMaybe<Array<Architecture>>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  childSimilarity?: InputMaybe<Scalars['Float']['input']>;
  cloudOnly?: InputMaybe<Scalars['Boolean']['input']>;
  cloudReadiness?: InputMaybe<Scalars['Float']['input']>;
  cveIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dockerImage?: InputMaybe<Scalars['String']['input']>;
  domainModelTag?: InputMaybe<Scalars['String']['input']>;
  eol?: InputMaybe<Scalars['Date']['input']>;
  eolExtendedSupport?: InputMaybe<Scalars['Date']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isEncrypted?: InputMaybe<Scalars['Boolean']['input']>;
  isInhouseTechnology?: InputMaybe<Scalars['Boolean']['input']>;
  isOpenSource?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isRepositoryIrrelevant?: InputMaybe<Scalars['Boolean']['input']>;
  license?: InputMaybe<Scalars['String']['input']>;
  licenseOptions?: InputMaybe<Array<TechnologyLicenseOptionInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfCve?: InputMaybe<Scalars['Int']['input']>;
  operatingSystems?: InputMaybe<Array<Scalars['String']['input']>>;
  parentName?: InputMaybe<Scalars['String']['input']>;
  portNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
  repositoryUrl?: InputMaybe<Scalars['String']['input']>;
  technologyType?: InputMaybe<TechnologyType>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  vendorName?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<VersionInput>;
  wikipediaBaseId?: InputMaybe<Scalars['String']['input']>;
};

export type TechnologyChildren = CloudProduct | Technology;

export type TechnologyChildrenConnection = {
  __typename: 'TechnologyChildrenConnection';
  edges: Array<TechnologyChildren>;
  paginationInfo: PaginationInfo;
};

export type TechnologyCreateInput = {
  aliasNames?: InputMaybe<Array<Scalars['String']['input']>>;
  allowBYOLOption?: InputMaybe<Scalars['Boolean']['input']>;
  architectures?: InputMaybe<Array<Architecture>>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  childSimilarity?: InputMaybe<Scalars['Float']['input']>;
  cloudOnly?: InputMaybe<Scalars['Boolean']['input']>;
  cloudReadiness?: InputMaybe<Scalars['Float']['input']>;
  cveIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dockerImage?: InputMaybe<Scalars['String']['input']>;
  domainModelTag?: InputMaybe<Scalars['String']['input']>;
  eol?: InputMaybe<Scalars['Date']['input']>;
  eolExtendedSupport?: InputMaybe<Scalars['Date']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isEncrypted?: InputMaybe<Scalars['Boolean']['input']>;
  isInhouseTechnology?: InputMaybe<Scalars['Boolean']['input']>;
  isOpenSource?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isRepositoryIrrelevant?: InputMaybe<Scalars['Boolean']['input']>;
  license?: InputMaybe<Scalars['String']['input']>;
  licenseOptions?: InputMaybe<Array<TechnologyLicenseOptionInput>>;
  name: Scalars['String']['input'];
  numberOfCve?: InputMaybe<Scalars['Int']['input']>;
  operatingSystems?: InputMaybe<Array<Scalars['String']['input']>>;
  ownedBy?: InputMaybe<Array<Scalars['String']['input']>>;
  parentName: Scalars['String']['input'];
  portNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
  repositoryUrl?: InputMaybe<Scalars['String']['input']>;
  technologyType?: InputMaybe<TechnologyType>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  vendorName?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<VersionInput>;
  wikipediaBaseId?: InputMaybe<Scalars['String']['input']>;
};

export type TechnologyCreateResponse = MutationResponse & {
  __typename: 'TechnologyCreateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  technology: Maybe<Technology>;
};

export type TechnologyDeleteResponse = MutationResponse & {
  __typename: 'TechnologyDeleteResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  technology: Maybe<Technology>;
};

export type TechnologyID = TaxonomyID & {
  __typename: 'TechnologyID';
  idType: TechID;
  name: Scalars['String']['output'];
  technologyVersion: Maybe<Scalars['String']['output']>;
  txtureId: Scalars['String']['output'];
  vendorName: Maybe<Scalars['String']['output']>;
};

export type TechnologyLicenseOption = {
  __typename: 'TechnologyLicenseOption';
  aliasNames: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  txtureId: Scalars['String']['output'];
};

export type TechnologyLicenseOptionInput = {
  aliasNames?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  txtureId: Scalars['String']['input'];
};

export type TechnologyProvidedByConnection = {
  __typename: 'TechnologyProvidedByConnection';
  edges: Array<CloudProduct>;
  paginationInfo: PaginationInfo;
};

export type TechnologySearchResultNode = {
  __typename: 'TechnologySearchResultNode';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  type: TechType;
};

export type TechnologyTermSearchResult = {
  __typename: 'TechnologyTermSearchResult';
  domainModelTag: Maybe<Scalars['String']['output']>;
  isRelevant: Maybe<Scalars['Boolean']['output']>;
  matchedName: Scalars['String']['output'];
  originalName: Scalars['String']['output'];
  taxonomyID: TaxonomyID;
};

export type TechnologyTo = {
  __typename: 'TechnologyTo';
  aliasNames: Maybe<Array<Scalars['String']['output']>>;
  architectures: Maybe<Array<Architecture>>;
  category: Maybe<Array<Scalars['String']['output']>>;
  childSimilarity: Maybe<Scalars['Float']['output']>;
  cloudOnly: Maybe<Scalars['Boolean']['output']>;
  cloudReadiness: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  dockerImage: Maybe<Scalars['String']['output']>;
  domainModelTag: Maybe<Scalars['String']['output']>;
  eol: Maybe<Scalars['Date']['output']>;
  eolExtendedSupport: Maybe<Scalars['Date']['output']>;
  imageUrl: Maybe<Scalars['String']['output']>;
  isEncrypted: Maybe<Scalars['Boolean']['output']>;
  isInhouseTechnology: Maybe<Scalars['Boolean']['output']>;
  isPublic: Maybe<Scalars['Boolean']['output']>;
  isRepositoryIrrelevant: Maybe<Scalars['Boolean']['output']>;
  license: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  numberOfCve: Maybe<Scalars['Int']['output']>;
  operatingSystems: Maybe<Array<Scalars['String']['output']>>;
  ownedBy: Maybe<Array<Scalars['String']['output']>>;
  parentName: Maybe<Scalars['String']['output']>;
  repositoryUrl: Maybe<Scalars['String']['output']>;
  technologyType: Maybe<TechnologyType>;
  type: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
  vendorInfo: Maybe<Vendor>;
  vendorName: Maybe<Scalars['String']['output']>;
  version: Maybe<Version>;
  wikipediaBaseId: Maybe<Scalars['String']['output']>;
};

export type TechnologyType = 'Application' | 'ApplicationVersion' | 'Category';

export type TechnologyUpdateInput = {
  aliasNames?: InputMaybe<Array<Scalars['String']['input']>>;
  allowBYOLOption?: InputMaybe<Scalars['Boolean']['input']>;
  architectures?: InputMaybe<Array<Architecture>>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  childSimilarity?: InputMaybe<Scalars['Float']['input']>;
  cloudOnly?: InputMaybe<Scalars['Boolean']['input']>;
  cloudReadiness?: InputMaybe<Scalars['Float']['input']>;
  cveIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dockerImage?: InputMaybe<Scalars['String']['input']>;
  domainModelTag?: InputMaybe<Scalars['String']['input']>;
  eol?: InputMaybe<Scalars['Date']['input']>;
  eolExtendedSupport?: InputMaybe<Scalars['Date']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isEncrypted?: InputMaybe<Scalars['Boolean']['input']>;
  isInhouseTechnology?: InputMaybe<Scalars['Boolean']['input']>;
  isOpenSource?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic: Scalars['Boolean']['input'];
  isRepositoryIrrelevant?: InputMaybe<Scalars['Boolean']['input']>;
  license?: InputMaybe<Scalars['String']['input']>;
  licenseOptions?: InputMaybe<Array<TechnologyLicenseOptionInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfCve?: InputMaybe<Scalars['Int']['input']>;
  operatingSystems?: InputMaybe<Array<Scalars['String']['input']>>;
  ownedBy?: InputMaybe<Array<Scalars['String']['input']>>;
  parentName?: InputMaybe<Scalars['String']['input']>;
  portNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
  repositoryUrl?: InputMaybe<Scalars['String']['input']>;
  technologyType?: InputMaybe<TechnologyType>;
  txtureId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  vendorName?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<VersionInput>;
  wikipediaBaseId?: InputMaybe<Scalars['String']['input']>;
};

export type TechnologyUpdateResponse = MutationResponse & {
  __typename: 'TechnologyUpdateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  technology: Maybe<Technology>;
};

export type TenancyInterface = {
  isPublic: Scalars['Boolean']['output'];
  ownedBy: Array<Scalars['String']['output']>;
};

export type TerraformInstanceInfo = {
  amount: Scalars['Int']['input'];
  sourceId?: InputMaybe<Scalars['String']['input']>;
  txtureId: Scalars['String']['input'];
};

export type TerraformScriptInfo = {
  __typename: 'TerraformScriptInfo';
  content: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TieredRates = {
  __typename: 'TieredRates';
  beginRange: Scalars['Float']['output'];
  endRange: Maybe<Scalars['Float']['output']>;
  pricePerUnit: Scalars['Float']['output'];
};

export type TrafficPriceItem = BasePriceItem & {
  __typename: 'TrafficPriceItem';
  defaultValue: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  fixedValue: Maybe<Scalars['Float']['output']>;
  parameterKey: Scalars['String']['output'];
  tieredRates: Array<TieredRates>;
  trafficType: TrafficType;
  type: PriceItemType;
  usageUnit: Scalars['String']['output'];
};

export type TrafficType = 'inbound' | 'interRegion' | 'interZone' | 'internet' | 'outbound';

export type TrialSubscription = BillingSubscription & {
  __typename: 'TrialSubscription';
  limits: Limits;
  until: Scalars['Date']['output'];
};

export type UpdateCloudProviderResponse = MutationResponse & {
  __typename: 'UpdateCloudProviderResponse';
  cloudProvider: Maybe<CloudProvider>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateSolutionResponse = MutationResponse & {
  __typename: 'UpdateSolutionResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  solution: Maybe<Solution>;
  success: Scalars['Boolean']['output'];
};

export type UpdateUserSettingsResponse = MutationResponse & {
  __typename: 'UpdateUserSettingsResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  user: User;
};

export type User = {
  __typename: 'User';
  company: Company;
  email: Scalars['String']['output'];
  firstname: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastname: Maybe<Scalars['String']['output']>;
  projects: Array<Project>;
  role: UserRole;
  settings: UserSettings;
};

export type UserChangeCount = {
  __typename: 'UserChangeCount';
  modifiedEntries: Scalars['Int']['output'];
  userEmail: Scalars['String']['output'];
};

export type UserInfo = {
  __typename: 'UserInfo';
  authenticated: Scalars['Boolean']['output'];
  email: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type UserRole = 'CompanyAdmin' | 'Default';

export type UserSettings = {
  __typename: 'UserSettings';
  betaShowBomView: Scalars['Boolean']['output'];
  currency: Scalars['String']['output'];
  hasWatchedIntroVideo: Scalars['Boolean']['output'];
};

export type UserSettingsInput = {
  currency?: InputMaybe<Scalars['String']['input']>;
  hasWatchedIntroVideo?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ValidationIssue = {
  __typename: 'ValidationIssue';
  additionalInfo: Maybe<Scalars['String']['output']>;
  level: LevelInfo;
  message: Scalars['String']['output'];
};

export type ValueType = 'boolean' | 'markdown' | 'number' | 'string' | 'stringArray' | 'url';

export type Vendor = {
  __typename: 'Vendor';
  aliasNames: Array<Scalars['String']['output']>;
  chrunchbaseUrl: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  headquarters: Maybe<Scalars['String']['output']>;
  linkedInUrl: Maybe<Scalars['String']['output']>;
  marketCap: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  twitterUrl: Maybe<Scalars['String']['output']>;
  txtureId: Scalars['ID']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export type VendorConnection = {
  __typename: 'VendorConnection';
  edges: Array<Vendor>;
  paginationInfo: PaginationInfo;
};

export type VendorCreateInput = {
  aliasNames: Array<Scalars['String']['input']>;
  chrunchbaseUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  headquarters?: InputMaybe<Scalars['String']['input']>;
  linkedInUrl?: InputMaybe<Scalars['String']['input']>;
  marketCap?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type VendorCreateMutationResponse = MutationResponse & {
  __typename: 'VendorCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  vendor: Maybe<Vendor>;
};

export type VendorDeleteMutationResponse = MutationResponse & {
  __typename: 'VendorDeleteMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  vendor: Maybe<Vendor>;
};

export type VendorTechFilterOptions = 'all' | 'relevant';

export type VendorUpdateInput = {
  aliasNames: Array<Scalars['String']['input']>;
  chrunchbaseUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  headquarters?: InputMaybe<Scalars['String']['input']>;
  linkedInUrl?: InputMaybe<Scalars['String']['input']>;
  marketCap?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type VendorUpdateMutationResponse = MutationResponse & {
  __typename: 'VendorUpdateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  vendor: Maybe<Vendor>;
};

export type Version = {
  __typename: 'Version';
  build: Maybe<Scalars['String']['output']>;
  hotfix: Maybe<Scalars['String']['output']>;
  major: Maybe<Scalars['String']['output']>;
  minor: Maybe<Scalars['String']['output']>;
  patch: Maybe<Scalars['String']['output']>;
  preRelease: Maybe<Scalars['String']['output']>;
  raw: Scalars['String']['output'];
  rx: Maybe<Scalars['String']['output']>;
  servicePack: Maybe<Scalars['String']['output']>;
  update: Maybe<Scalars['String']['output']>;
};

export type VersionInput = {
  build?: InputMaybe<Scalars['String']['input']>;
  hotfix?: InputMaybe<Scalars['String']['input']>;
  major?: InputMaybe<Scalars['String']['input']>;
  minor?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<Scalars['String']['input']>;
  preRelease?: InputMaybe<Scalars['String']['input']>;
  raw: Scalars['String']['input'];
  rx?: InputMaybe<Scalars['String']['input']>;
  servicePack?: InputMaybe<Scalars['String']['input']>;
  update?: InputMaybe<Scalars['String']['input']>;
};

export type WithSourceInfo = {
  sourceInfo: Array<SourceInfo>;
};

export type AccountQueryQueryVariables = Exact<{ [key: string]: never }>;

export type AccountQueryQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    email: string;
    company: {
      __typename: 'Company';
      id: string;
      name: string;
      subscription:
        | { __typename: 'FreeSubscription'; limits: { __typename: 'Limits'; maxProjectsPerUser: number } }
        | {
            __typename: 'FreemiumSubscription';
            limits: { __typename: 'Limits'; maxProjectsPerUser: number; maxSolutionsPerProject: number };
          }
        | { __typename: 'PaySubscription'; subscriptionId: string }
        | { __typename: 'TrialSubscription'; until: any };
    };
  };
};

export type AccountTaxInfoQueryQueryVariables = Exact<{ [key: string]: never }>;

export type AccountTaxInfoQueryQuery = {
  __typename: 'Query';
  me: { __typename: 'User'; email: string; company: { __typename: 'Company'; id: string; hasTaxId: boolean } };
};

export type CompanyTaxIdMutationMutationVariables = Exact<{
  taxId: TaxIdCreateInput;
}>;

export type CompanyTaxIdMutationMutation = {
  __typename: 'Mutation';
  addTaxId: { __typename: 'CompanyUpdateResponse'; success: boolean; message: string; code: string };
};

export type CompanySubscriptionPropertiesFragment = {
  __typename: 'Company';
  id: string;
  canSubscribeToFreeTrial: boolean;
  subscription:
    | {
        __typename: 'FreeSubscription';
        limits: {
          __typename: 'Limits';
          maxProjectsPerUser: number;
          maxSolutionsPerProject: number;
          maxServicesPerSolution: number;
          canAccessReservedPricing: boolean;
          canAccessCertification: boolean;
          canGenAlternativeSolutions: boolean;
          canExportSolution: boolean;
        };
      }
    | {
        __typename: 'FreemiumSubscription';
        limits: {
          __typename: 'Limits';
          maxProjectsPerUser: number;
          maxSolutionsPerProject: number;
          maxServicesPerSolution: number;
          canAccessReservedPricing: boolean;
          canAccessCertification: boolean;
          canGenAlternativeSolutions: boolean;
          canExportSolution: boolean;
        };
      }
    | {
        __typename: 'PaySubscription';
        subscriptionId: string;
        limits: {
          __typename: 'Limits';
          maxProjectsPerUser: number;
          maxSolutionsPerProject: number;
          maxServicesPerSolution: number;
          canAccessReservedPricing: boolean;
          canAccessCertification: boolean;
          canGenAlternativeSolutions: boolean;
          canExportSolution: boolean;
        };
      }
    | {
        __typename: 'TrialSubscription';
        until: any;
        limits: {
          __typename: 'Limits';
          maxProjectsPerUser: number;
          maxSolutionsPerProject: number;
          maxServicesPerSolution: number;
          canAccessReservedPricing: boolean;
          canAccessCertification: boolean;
          canGenAlternativeSolutions: boolean;
          canExportSolution: boolean;
        };
      };
};

export type SubscriptionQueryQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionQueryQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    email: string;
    company: {
      __typename: 'Company';
      id: string;
      canSubscribeToFreeTrial: boolean;
      subscription:
        | {
            __typename: 'FreeSubscription';
            limits: {
              __typename: 'Limits';
              maxProjectsPerUser: number;
              maxSolutionsPerProject: number;
              maxServicesPerSolution: number;
              canAccessReservedPricing: boolean;
              canAccessCertification: boolean;
              canGenAlternativeSolutions: boolean;
              canExportSolution: boolean;
            };
          }
        | {
            __typename: 'FreemiumSubscription';
            limits: {
              __typename: 'Limits';
              maxProjectsPerUser: number;
              maxSolutionsPerProject: number;
              maxServicesPerSolution: number;
              canAccessReservedPricing: boolean;
              canAccessCertification: boolean;
              canGenAlternativeSolutions: boolean;
              canExportSolution: boolean;
            };
          }
        | {
            __typename: 'PaySubscription';
            subscriptionId: string;
            limits: {
              __typename: 'Limits';
              maxProjectsPerUser: number;
              maxSolutionsPerProject: number;
              maxServicesPerSolution: number;
              canAccessReservedPricing: boolean;
              canAccessCertification: boolean;
              canGenAlternativeSolutions: boolean;
              canExportSolution: boolean;
            };
          }
        | {
            __typename: 'TrialSubscription';
            until: any;
            limits: {
              __typename: 'Limits';
              maxProjectsPerUser: number;
              maxSolutionsPerProject: number;
              maxServicesPerSolution: number;
              canAccessReservedPricing: boolean;
              canAccessCertification: boolean;
              canGenAlternativeSolutions: boolean;
              canExportSolution: boolean;
            };
          };
    };
  };
  billingConfig: { __typename: 'BillingConfig'; stripeApiKey: string };
};

export type SubscribeFreeTrialMutationVariables = Exact<{ [key: string]: never }>;

export type SubscribeFreeTrialMutation = {
  __typename: 'Mutation';
  subscribeFreeTrial: {
    __typename: 'SubscriptionChangeResponse';
    code: string;
    success: boolean;
    message: string;
    company: {
      __typename: 'Company';
      id: string;
      canSubscribeToFreeTrial: boolean;
      subscription:
        | {
            __typename: 'FreeSubscription';
            limits: {
              __typename: 'Limits';
              maxProjectsPerUser: number;
              maxSolutionsPerProject: number;
              maxServicesPerSolution: number;
              canAccessReservedPricing: boolean;
              canAccessCertification: boolean;
              canGenAlternativeSolutions: boolean;
              canExportSolution: boolean;
            };
          }
        | {
            __typename: 'FreemiumSubscription';
            limits: {
              __typename: 'Limits';
              maxProjectsPerUser: number;
              maxSolutionsPerProject: number;
              maxServicesPerSolution: number;
              canAccessReservedPricing: boolean;
              canAccessCertification: boolean;
              canGenAlternativeSolutions: boolean;
              canExportSolution: boolean;
            };
          }
        | {
            __typename: 'PaySubscription';
            subscriptionId: string;
            limits: {
              __typename: 'Limits';
              maxProjectsPerUser: number;
              maxSolutionsPerProject: number;
              maxServicesPerSolution: number;
              canAccessReservedPricing: boolean;
              canAccessCertification: boolean;
              canGenAlternativeSolutions: boolean;
              canExportSolution: boolean;
            };
          }
        | {
            __typename: 'TrialSubscription';
            until: any;
            limits: {
              __typename: 'Limits';
              maxProjectsPerUser: number;
              maxSolutionsPerProject: number;
              maxServicesPerSolution: number;
              canAccessReservedPricing: boolean;
              canAccessCertification: boolean;
              canGenAlternativeSolutions: boolean;
              canExportSolution: boolean;
            };
          };
    } | null;
  };
};

export type DowngradeToFreemiumMutationVariables = Exact<{ [key: string]: never }>;

export type DowngradeToFreemiumMutation = {
  __typename: 'Mutation';
  downgradeToFreemium: {
    __typename: 'SubscriptionChangeResponse';
    code: string;
    success: boolean;
    message: string;
    company: {
      __typename: 'Company';
      id: string;
      canSubscribeToFreeTrial: boolean;
      subscription:
        | {
            __typename: 'FreeSubscription';
            limits: {
              __typename: 'Limits';
              maxProjectsPerUser: number;
              maxSolutionsPerProject: number;
              maxServicesPerSolution: number;
              canAccessReservedPricing: boolean;
              canAccessCertification: boolean;
              canGenAlternativeSolutions: boolean;
              canExportSolution: boolean;
            };
          }
        | {
            __typename: 'FreemiumSubscription';
            limits: {
              __typename: 'Limits';
              maxProjectsPerUser: number;
              maxSolutionsPerProject: number;
              maxServicesPerSolution: number;
              canAccessReservedPricing: boolean;
              canAccessCertification: boolean;
              canGenAlternativeSolutions: boolean;
              canExportSolution: boolean;
            };
          }
        | {
            __typename: 'PaySubscription';
            subscriptionId: string;
            limits: {
              __typename: 'Limits';
              maxProjectsPerUser: number;
              maxSolutionsPerProject: number;
              maxServicesPerSolution: number;
              canAccessReservedPricing: boolean;
              canAccessCertification: boolean;
              canGenAlternativeSolutions: boolean;
              canExportSolution: boolean;
            };
          }
        | {
            __typename: 'TrialSubscription';
            until: any;
            limits: {
              __typename: 'Limits';
              maxProjectsPerUser: number;
              maxSolutionsPerProject: number;
              maxServicesPerSolution: number;
              canAccessReservedPricing: boolean;
              canAccessCertification: boolean;
              canGenAlternativeSolutions: boolean;
              canExportSolution: boolean;
            };
          };
    } | null;
  };
};

export type MeQueryQueryVariables = Exact<{ [key: string]: never }>;

export type MeQueryQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    email: string;
    firstname: string | null;
    lastname: string | null;
    settings: { __typename: 'UserSettings'; currency: string };
  };
};

export type UserSettingsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type UserSettingsQueryQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    email: string;
    settings: { __typename: 'UserSettings'; hasWatchedIntroVideo: boolean; betaShowBomView: boolean; currency: string };
  };
};

export type UpdateUserSettingsMutationMutationVariables = Exact<{
  settings: UserSettingsInput;
}>;

export type UpdateUserSettingsMutationMutation = {
  __typename: 'Mutation';
  updateUserSettings: {
    __typename: 'UpdateUserSettingsResponse';
    success: boolean;
    user: {
      __typename: 'User';
      email: string;
      settings: { __typename: 'UserSettings'; hasWatchedIntroVideo: boolean; currency: string };
    };
  };
};

export type CloudProductNameQueryQueryVariables = Exact<{
  productId: Scalars['String']['input'];
}>;

export type CloudProductNameQueryQuery = {
  __typename: 'Query';
  cloudProduct: {
    __typename: 'CloudProduct';
    txtureId: string;
    name: string;
    provider: { __typename: 'CloudProvider'; name: string };
  } | null;
};

export type ProductInstancePathQueryQueryVariables = Exact<{
  instanceId: Scalars['String']['input'];
}>;

export type ProductInstancePathQueryQuery = {
  __typename: 'Query';
  productInstanceById: {
    __typename: 'ProductInstance';
    id: string;
    txtureId: string;
    name: string;
    product: {
      __typename: 'CloudProduct';
      txtureId: string;
      name: string;
      provider: { __typename: 'CloudProvider'; name: string };
    };
  } | null;
};

export type DataCenterPathQueryQueryVariables = Exact<{
  dataCenterId: Scalars['ID']['input'];
}>;

export type DataCenterPathQueryQuery = {
  __typename: 'Query';
  dataCenterById: {
    __typename: 'DataCenter';
    txtureId: string;
    name: string;
    provider: { __typename: 'CloudProvider'; txtureId: string; name: string };
  } | null;
};

export type DataCentersQueryQueryVariables = Exact<{ [key: string]: never }>;

export type DataCentersQueryQuery = {
  __typename: 'Query';
  dataCenters: Array<{
    __typename: 'DataCenter';
    txtureId: string;
    name: string;
    key: string;
    location: {
      __typename: 'Location';
      name: string;
      path: Array<string>;
      coordinates: { __typename: 'Coordinates'; lat: number; long: number } | null;
    };
    provider: {
      __typename: 'CloudProvider';
      txtureId: string;
      name: string;
      imageUrl: string | null;
      iconImageUrl: string | null;
    };
  }>;
};

export type CloudProvidersStatsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type CloudProvidersStatsQueryQuery = {
  __typename: 'Query';
  cloudProviderStats: {
    __typename: 'CloudProviderStats';
    numberOfProviders: number;
    numberOfProducts: number;
    numberOfProductInstances: number;
    numberOfDataCenters: number;
  };
};

export const CompanySubscriptionPropertiesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanySubscriptionProperties' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canSubscribeToFreeTrial' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'limits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'maxProjectsPerUser' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxSolutionsPerProject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxServicesPerSolution' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canAccessReservedPricing' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canAccessCertification' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canGenAlternativeSolutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canExportSolution' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TrialSubscription' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'until' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaySubscription' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanySubscriptionPropertiesFragment, unknown>;
export const AccountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscription' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TrialSubscription' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'until' } }],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FreeSubscription' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'limits' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxProjectsPerUser' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'FreemiumSubscription' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'limits' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxProjectsPerUser' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxSolutionsPerProject' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaySubscription' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountQueryQuery, AccountQueryQueryVariables>;
export const AccountTaxInfoQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountTaxInfoQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasTaxId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountTaxInfoQueryQuery, AccountTaxInfoQueryQueryVariables>;
export const CompanyTaxIdMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompanyTaxIdMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'TaxIdCreateInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTaxId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'taxId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyTaxIdMutationMutation, CompanyTaxIdMutationMutationVariables>;
export const SubscriptionQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubscriptionQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanySubscriptionProperties' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'stripeApiKey' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanySubscriptionProperties' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canSubscribeToFreeTrial' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'limits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'maxProjectsPerUser' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxSolutionsPerProject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxServicesPerSolution' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canAccessReservedPricing' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canAccessCertification' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canGenAlternativeSolutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canExportSolution' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TrialSubscription' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'until' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaySubscription' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionQueryQuery, SubscriptionQueryQueryVariables>;
export const SubscribeFreeTrialDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubscribeFreeTrial' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribeFreeTrial' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanySubscriptionProperties' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanySubscriptionProperties' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canSubscribeToFreeTrial' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'limits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'maxProjectsPerUser' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxSolutionsPerProject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxServicesPerSolution' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canAccessReservedPricing' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canAccessCertification' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canGenAlternativeSolutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canExportSolution' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TrialSubscription' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'until' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaySubscription' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscribeFreeTrialMutation, SubscribeFreeTrialMutationVariables>;
export const DowngradeToFreemiumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DowngradeToFreemium' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downgradeToFreemium' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanySubscriptionProperties' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanySubscriptionProperties' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canSubscribeToFreeTrial' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'limits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'maxProjectsPerUser' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxSolutionsPerProject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxServicesPerSolution' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canAccessReservedPricing' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canAccessCertification' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canGenAlternativeSolutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canExportSolution' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TrialSubscription' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'until' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaySubscription' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DowngradeToFreemiumMutation, DowngradeToFreemiumMutationVariables>;
export const MeQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MeQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'currency' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQueryQuery, MeQueryQueryVariables>;
export const UserSettingsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserSettingsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasWatchedIntroVideo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'betaShowBomView' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserSettingsQueryQuery, UserSettingsQueryQueryVariables>;
export const UpdateUserSettingsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserSettingsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'settings' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserSettingsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'settings' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'settings' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'settings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'hasWatchedIntroVideo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserSettingsMutationMutation, UpdateUserSettingsMutationMutationVariables>;
export const CloudProductNameQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CloudProductNameQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cloudProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'txtureId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'txtureId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CloudProductNameQueryQuery, CloudProductNameQueryQueryVariables>;
export const ProductInstancePathQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductInstancePathQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instanceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productInstanceById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'txtureId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'instanceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'txtureId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'txtureId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'provider' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductInstancePathQueryQuery, ProductInstancePathQueryQueryVariables>;
export const DataCenterPathQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCenterPathQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataCenterId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataCenterById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataCenterId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataCenterId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'txtureId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'txtureId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCenterPathQueryQuery, DataCenterPathQueryQueryVariables>;
export const DataCentersQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataCentersQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataCenters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'txtureId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coordinates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'long' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'txtureId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'iconImageUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCentersQueryQuery, DataCentersQueryQueryVariables>;
export const CloudProvidersStatsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CloudProvidersStatsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cloudProviderStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfProviders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfProducts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfProductInstances' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfDataCenters' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CloudProvidersStatsQueryQuery, CloudProvidersStatsQueryQueryVariables>;
