import { valueNotNullable } from './filter';
export { default as arrayMoveImmutable } from 'array-move';
/** Returns the sum of all values of the given array. */ export function arraySum(arr) {
    return arr.reduce((acc, v)=>acc + v, 0);
}
/**
 * Sums up all number values of an object array.
 * @returns object with sums.
 */ export function arrayNestedSum(arr) {
    const output = {};
    for(let index = 0; index < arr.length; index++){
        for (let [key, value] of Object.entries(arr[index])){
            if (typeof value === 'number') {
                output[key] = (output[key] ?? 0) + value;
            }
        }
    }
    return output;
}
/** Returns the average of all values of the given array.
 *
 * Returns 0 for empty arrays
 */ export function arrayAvg(arr) {
    if (arr.length === 0) {
        return 0;
    }
    return arraySum(arr) / arr.length;
}
export function uniqueArray(items) {
    return [
        ...new Set(items)
    ];
}
/**
 * Splits an array into two sub-arrays based on the split function.
 */ export function splitArray(items, isPartOfA) {
    const a = [];
    const b = [];
    for (const item of items){
        if (isPartOfA(item)) {
            a.push(item);
        } else {
            b.push(item);
        }
    }
    return [
        a,
        b
    ];
}
/**
 * Gets the top X entries from the array
 */ export function arrayHead(num, items) {
    return items.filter((_, i)=>i < num);
}
/**
 * Gets the first element iff it is the only element
 */ export function arrayOnly(items) {
    if (items.length === 1) {
        return items[0];
    }
    return undefined;
}
/**
 * Returns a new array with called `updater` on matching elements.
 *
 * If no element was found and a `defaultVal` was given, a new element is pushed.
 */ export function updateArrayItem(arr, find, updater, defaultVal) {
    let hasMatch = false;
    const newItems = arr.map((el)=>{
        if (find(el)) {
            hasMatch = true;
            return updater(el);
        }
        return el;
    });
    if (!hasMatch && defaultVal) {
        newItems.push(defaultVal);
    }
    return newItems;
}
/**
 * Gets the first element (if any)
 *
 * explicitly types the missing case as null
 */ export function arrayFirst(arr) {
    return arr[0] ?? null;
}
/**
 * Gets the last element (if any)
 */ export function arrayTail(arr) {
    return arr.at(arr.length - 1);
}
/** Gets the first non nullable value */ export function arrayCoalesce(arr) {
    return arrayFirst(arr.filter(valueNotNullable));
}
/**
 * Picks the properties inside the object array by the given keys.
 */ export function arrayPickNestedKeys(arr, keys) {
    return arr.map((x)=>({
            ...keys.reduce((prevKey, currKey)=>({
                    ...prevKey,
                    [currKey]: x[currKey]
                }), {})
        }));
}
/**
 * Pushes a value N times into the array
 */ export function pushNTimes(items, value, times) {
    for(let i = 0; i < times; i++){
        if (Array.isArray(value)) {
            items.push(...value);
        } else {
            items.push(value);
        }
    }
    return items;
}
/** creates an array of numbers within the given start and end range and the given increment */ export function range(start, end, increment = 1) {
    const res = [];
    for(let i = start; i <= end; i += increment){
        res.push(i);
    }
    return res;
}
/**
 * This function gets a union type (as type param) & every value of a union type
 * It will check if every key of the union is passed as a param
 * - if any is missing it will show an error
 * - if any is too much (not in the union type) it will show an error
 * - if all are matching it will return the array with all values
 */ export const unionTypeToExhaustiveArray = ()=>(...x)=>x;
export const sortedPush = (array, newElement, comparator)=>{
    let i = 0;
    for(; i < array.length; i++){
        const el = array[i];
        if (comparator(newElement, el) < 0) {
            break;
        }
    }
    array.splice(i, 0, newElement);
    return array;
};
