import React from 'react';
export function useTimer({ onUpdate, onStart, onReset, onPause, autoStart = false, interval = 1000 } = {}) {
    const [value, setValue] = React.useState(0);
    const [running, setRunning] = React.useState(false);
    const data = React.useRef({
        timeout: null,
        expected: 0
    });
    React.useEffect(()=>{
        if (autoStart) {
            start();
        }
        return reset;
    }, []);
    React.useEffect(()=>{
        onUpdate?.(value);
    }, [
        value
    ]);
    function start() {
        if (data.current.timeout !== null) {
            return;
        }
        data.current.expected = Date.now() + interval;
        data.current.timeout = window.setTimeout(timeStep, interval);
        setRunning(true);
        onStart?.();
        function timeStep() {
            const drift = Date.now() - data.current.expected;
            data.current.expected += interval;
            setValue((v)=>v + 1);
            data.current.timeout = window.setTimeout(timeStep, Math.max(0, interval - drift));
        }
    }
    function reset() {
        onReset?.(value);
        window.clearTimeout(data.current.timeout || undefined);
        data.current.timeout = null;
        setRunning(false);
        setValue(0);
    }
    function pause() {
        onPause?.(value);
        window.clearTimeout(data.current.timeout || undefined);
        data.current.timeout = null;
        setRunning(false);
    }
    return {
        value,
        isRunning: running,
        start,
        reset,
        pause
    };
}
