import * as React from 'react';
import { useDebouncedValue } from './useDebouncedValue';
export function useDebouncedState(initialValue, delay) {
    const [value, setValue] = React.useState(initialValue);
    const debouncedValue = useDebouncedValue(value, delay);
    return [
        value,
        debouncedValue,
        setValue
    ];
}
