/**
 * Extracts the error message from the given error
 * @param error
 * @returns
 */ export function getErrorMessage(error) {
    if (error && typeof error === 'object') {
        if ('message' in error) {
            return `${error.message}`;
        } else if ('msg' in error) {
            return error.msg;
        } else {
            return `Unknown Error: ${JSON.stringify(error)}`;
        }
    } else if (typeof error === 'string') {
        return error;
    } else {
        return `Unknown Error: ${JSON.stringify(error)}`;
    }
}
const NETWORK_ERROR_MSG = 'TypeError: Failed to fetch';
export function getFormattedErrorMessage(err) {
    const errMsg = getErrorMessage(err);
    // test for network connection errors and adapt message
    if (errMsg === NETWORK_ERROR_MSG) {
        return {
            title: 'No connection',
            errMsg: 'Please check your internet-connection and try again.'
        };
    }
    return {
        errMsg
    };
}
