const BASE = 1000;
const EXPONENT_MAP = {
    g: 0,
    kg: 1,
    t: 2
};
export const convertMassUnit = (value, from, to, round = false)=>{
    const result = value * BASE ** EXPONENT_MAP[from] / BASE ** EXPONENT_MAP[to];
    return round ? Math.round(result) : result;
};
