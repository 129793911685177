import { css } from '@emotion/react';
import { PropsApi, styleProps } from '../props';
import { Theme } from '../theme';

type Size = keyof Theme['typography']['fontSizes'];
type Weight = keyof Theme['typography']['fontWeights'];
type LineHeight = keyof Theme['typography']['lineHeight'];
type LetterSpacing = keyof Theme['typography']['letterSpacing'];

export type TypoProps = {
  fontSize?: Size;
  fontWeight?: Weight;
  lineHeight?: LineHeight;

  align?: React.CSSProperties['textAlign'];
  casing?: React.CSSProperties['textTransform'];
  decoration?: React.CSSProperties['textDecoration'];
  letterSpacing?: LetterSpacing;
} & PropsApi;

type RequiredTypoProps = PartialRequired<TypoProps, 'fontSize' | 'fontWeight' | 'lineHeight'>;

export const typoStyles = (
  props: {
    theme: Theme;
  } & RequiredTypoProps,
) => css`
  font-size: ${props.theme.typography.fontSizes[props.fontSize]};
  font-weight: ${props.theme.typography.fontWeights[props.fontWeight]};
  line-height: ${props.theme.typography.lineHeight[props.lineHeight] ?? props.lineHeight};

  margin: 0;
  padding: 0;

  text-align: ${props.align ?? 'inherit'};
  text-transform: ${props.casing ?? 'inherit'};
  text-decoration: ${props.decoration ?? 'inherit'};

  /* In uppercased casing mode, we want to add some letter spacing. */
  letter-spacing: ${props.letterSpacing ??
  (props.casing === 'uppercase' ? props.theme.typography.letterSpacing.wider : 'inherit')};

  ${styleProps(props)}
`;
