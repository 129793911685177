/**
 * finds the "best fitting" unit for a given value
 * where best fitting means smallest conversion greater or equal to 1
 *
 * @param convertUnit function which converts a value from unit A to unit B
 * @param units sorted (smallest to biggest unit) subset of available units
 * @param baseUnit the unit of the initial value
 **/ export function findReadableUnitConversion(convertUnit, units, baseUnit) {
    return (initialValue)=>{
        const conversions = units.map((unit)=>({
                unit,
                value: convertUnit(initialValue, baseUnit, unit)
            }));
        if (initialValue === 0) {
            const bestFit = conversions.find((c)=>c.unit === baseUnit);
            return {
                conversions,
                bestFit,
                bestFitStr: bestFitToString(bestFit),
                readableConversions: [
                    bestFit
                ]
            };
        }
        const firstValueSmallerOne = conversions.findIndex(({ value })=>Math.abs(value) < 1);
        const bestFit = Math.max(0, firstValueSmallerOne === -1 ? conversions.length - 1 // biggest conversion available
         : firstValueSmallerOne - 1);
        return {
            conversions,
            bestFit: conversions[bestFit],
            bestFitStr: bestFitToString(conversions[bestFit]),
            readableConversions: conversions.slice(Math.max(bestFit - 1, 0), bestFit + 2)
        };
    };
}
function bestFitToString(bestFit) {
    return `${bestFit.value} ${bestFit.unit}`;
}
