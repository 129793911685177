import { faClipboard } from '@fortawesome/pro-solid-svg-icons';
import * as React from 'react';
import { useCopyToClipboard } from 'react-use';
import { Icon } from '../Icon';
import { FormattedMessage, formatMessage } from '../i18n';
import { Button } from './Button';
import { ButtonIconOnly } from './ButtonIconOnly';

type Props = {
  text: string;
  mode?: 'inline' | 'button';
  onSuccess?: (message: string) => void;
  onError?: (message: string, error?: Error) => void;
  className?: string;
};

export const ClipboardButton: React.FC<Props> = ({ text, mode = 'inline', onSuccess, onError, className }) => {
  const [copyState, copyToClipboard] = useCopyToClipboard();

  React.useEffect(() => {
    if (copyState.error) {
      onError?.(formatMessage('clipboardButton.notification.error'), copyState.error);
    }
  }, [copyState]);

  function copy() {
    copyToClipboard(text);
    onSuccess?.(formatMessage('clipboardButton.notification.success'));
  }

  if (mode === 'inline') {
    return (
      <span>
        <ButtonIconOnly
          className={className}
          tooltip={<FormattedMessage id="clipboardButton.title" />}
          icon={faClipboard}
          onClick={copy}
        />
      </span>
    );
  }

  return (
    <Button color="primary" onClick={copy} className={className}>
      <Icon icon={faClipboard} />
      <span>
        <FormattedMessage id="clipboardButton.title" />
      </span>
    </Button>
  );
};
