import { InMemoryCache } from '@apollo/client';
import possibleTypes from './insider-possible-types.json';
/**
 * Shared apollo cache options.
 *
 * https://www.apollographql.com/docs/react/caching/cache-configuration/
 */ export function getApolloCache() {
    return new InMemoryCache({
        // we need to tell manually the cache that the following mutation responses implement the interface
        // because the server response is validated and filtered again on the client side. Without this config,
        // the interface fields are getting dropped
        possibleTypes,
        typePolicies: {
            Technology: {
                keyFields: [
                    'name'
                ],
                fields: {
                    children: {
                        merge (existing, incoming, { mergeObjects }) {
                            return mergeObjects(existing, incoming);
                        }
                    }
                }
            },
            Certification: {
                keyFields: [
                    'name'
                ]
            },
            CloudProvider: {
                keyFields: [
                    'name'
                ]
            },
            CloudProduct: {
                keyFields: [
                    'txtureId'
                ]
            },
            ProductInstance: {
                keyFields: [
                    'txtureId'
                ]
            },
            Location: {
                keyFields: [
                    'name'
                ],
                fields: {
                    children: {
                        merge (existing, incoming, { mergeObjects }) {
                            return mergeObjects(existing, incoming);
                        }
                    }
                }
            },
            PricingCrawler: {
                keyFields: [
                    'name'
                ]
            },
            DataCenter: {
                keyFields: [
                    'txtureId'
                ]
            },
            ProductAttribute: {
                keyFields: [
                    'attributeKey'
                ]
            },
            Vendor: {
                keyFields: [
                    'txtureId'
                ]
            },
            MigrationTool: {
                keyFields: [
                    'txtureId'
                ]
            },
            Project: {
                keyFields: [
                    'id'
                ],
                fields: {
                    dataInput: {
                        merge (existing, incoming, { mergeObjects }) {
                            return mergeObjects(existing, incoming);
                        }
                    }
                }
            },
            DataInput: {
                keyFields: false
            },
            DataInputStats: {
                keyFields: false
            },
            User: {
                keyFields: [
                    'email'
                ],
                fields: {
                    settings: {
                        merge (existing, incoming, { mergeObjects }) {
                            return mergeObjects(existing, incoming);
                        }
                    }
                }
            },
            UserSettings: {
                keyFields: false
            },
            Solution: {
                keyFields: [
                    'id'
                ]
            },
            SolutionItem: {
                keyFields: false
            },
            ProductInstanceConfiguration: {
                keyFields: false
            }
        }
    });
}
