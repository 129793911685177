import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Theme, ThemeColors, theme } from '@txt/core.styles';
import { mergeRefs } from '@txt/core.utils/mergeRefs';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { variant } from 'styled-system';
import { Icon } from '../Icon';
import { Tooltip } from '../tooltip';

type ButtonColors = {
  bg: ThemeColors;
  border?: ThemeColors;
  label: ThemeColors;
  icon?: ThemeColors;
};

type ButtonStatesConfig = {
  default: ButtonColors;
  disabled: ButtonColors;
  hover: ButtonColors;
  focus: ButtonColors;
  active: ButtonColors;
};

const createVariant = (tokens: ButtonStatesConfig) => (t: Theme) => {
  const color = (color: ThemeColors) => theme(`palette.${color}` as any)({ theme: t }) ?? color;

  const getVariables = (token: keyof ButtonStatesConfig) => css`
    --label-color: ${color(tokens[token].label)};
    --bg-color: ${color(tokens[token].bg)};
    --border-color: ${color(tokens[token].border ?? tokens[token].bg)};
    --icon-color: ${color(tokens[token].icon ?? tokens[token].label)};
  `;

  return css`
    ${getVariables('default')};

    :disabled,
    &[data-disabled='true'] {
      ${getVariables('disabled')};
    }

    &:not([data-disabled='true']) {
      &:hover,
      &[data-hover='true'] {
        ${getVariables('hover')};
      }

      &:focus,
      &[data-focus='true'] {
        ${getVariables('focus')};
      }

      &:active,
      &[data-active='true'] {
        ${getVariables('active')};
      }
    }
  `;
};

const variants = variant({
  prop: 'variant',
  variants: {
    contained: createVariant({
      default: {
        bg: 'primary.600',
        border: 'primary.600',
        label: 'white',
      },
      disabled: {
        bg: 'grey.300',
        label: 'grey.100',
      },
      hover: {
        bg: 'primary.500',
        label: 'white',
      },
      focus: {
        bg: 'primary.500',
        label: 'white',
        border: 'primary.200',
      },
      active: {
        bg: 'primary.700',
        label: 'white',
      },
    }),
    inverted: createVariant({
      default: {
        bg: 'white',
        border: 'white',
        label: 'primary.700',
        icon: 'primary.600',
      },
      disabled: {
        bg: 'white',
        label: 'grey.100',
      },
      hover: {
        bg: 'primary.50',
        label: 'primary.700',
        icon: 'primary.600',
      },
      focus: {
        bg: 'primary.100',
        label: 'primary.700',
        border: 'primary.200',
        icon: 'primary.600',
      },
      active: {
        bg: 'primary.200',
        label: 'primary.700',
        icon: 'primary.600',
      },
    }),
    'inverted-grey': createVariant({
      default: {
        bg: 'white',
        border: 'white',
        label: 'grey.700',
      },
      disabled: {
        bg: 'white',
        label: 'grey.300',
      },
      hover: {
        bg: 'grey.100',
        label: 'grey.900',
      },
      focus: {
        bg: 'grey.200',
        label: 'grey.900',
        border: 'primary.200',
      },
      active: {
        bg: 'grey.200',
        label: 'grey.900',
      },
    }),
    text: createVariant({
      default: {
        bg: 'transparent',
        label: 'primary.700',
        icon: 'primary.600',
      },
      disabled: {
        bg: 'transparent',
        label: 'grey.300',
      },
      hover: {
        bg: 'transparent',
        label: 'primary.500',
      },
      focus: {
        bg: 'transparent',
        label: 'primary.500',
        border: 'primary.200',
      },
      active: {
        bg: 'grey.50',
        label: 'primary.700',
      },
    }),
    'text-grey': createVariant({
      default: {
        bg: 'transparent',
        label: 'grey.700',
        icon: 'grey.600',
      },
      disabled: {
        bg: 'transparent',
        label: 'grey.300',
      },
      hover: {
        bg: 'transparent',
        label: 'grey.900',
        icon: 'grey.800',
      },
      focus: {
        bg: 'grey.50',
        label: 'grey.900',
        icon: 'grey.800',
        border: 'primary.200',
      },
      active: {
        bg: 'grey.50',
        label: 'primary.900',
        icon: 'grey.800',
      },
    }),
    outlined: createVariant({
      default: {
        bg: 'transparent',
        border: 'primary.600',
        label: 'primary.700',
      },
      disabled: {
        bg: 'transparent',
        label: 'grey.300',
        border: 'grey.300',
      },
      hover: {
        bg: 'transparent',
        label: 'primary.500',
        border: 'primary.600',
      },
      focus: {
        bg: 'transparent',
        label: 'primary.500',
        border: 'primary.300',
      },
      active: {
        bg: 'white',
        label: 'primary.500',
        border: 'primary.600',
      },
    }),
    'outlined-grey': createVariant({
      default: {
        bg: 'transparent',
        label: 'grey.700',
        border: 'grey.700',
      },
      disabled: {
        bg: 'transparent',
        label: 'grey.300',
        border: 'grey.300',
      },
      hover: {
        bg: 'transparent',
        label: 'grey.500',
        border: 'grey.500',
      },
      focus: {
        bg: 'transparent',
        label: 'grey.500',
        border: 'primary.300',
      },
      active: {
        bg: 'white',
        label: 'grey.500',
        border: 'grey.500',
      },
    }),
  },
});

const sizes = variant({
  prop: 'size',
  variants: {
    xs: {
      height: '26px',
      fontSize: (theme: Theme) => theme.typography.fontSizes.sm,
      padding: (theme: Theme) => `0 ${theme.space[2]}`,
    },
    s: {
      height: '36px',
      fontSize: (theme: Theme) => theme.typography.fontSizes.md,
      padding: (theme: Theme) => `0 ${theme.space[3]}`,
    },
    m: {
      height: '46px',
      fontSize: (theme: Theme) => theme.typography.fontSizes.xl,
      padding: (theme: Theme) => `0 ${theme.space[3]}`,
    },
    l: {
      height: '56px',
      fontSize: (theme: Theme) => theme.typography.fontSizes.xl3,
      padding: (theme: Theme) => `0 ${theme.space[4]}`,
    },
  },
});

type Variant = 'contained' | 'inverted' | 'inverted-grey' | 'text' | 'text-grey' | 'outlined' | 'outlined-grey';
type Size = 'xs' | 's' | 'm' | 'l';

const transition = css`
  transition: all 150ms ease-in;
`;

// Reset some global styles that are interfering with the button
// This is a hack and should be removed when the global styles are fixed
const hack = css`
  .fa {
    font-size: 1em;
  }

  span + .fa,
  .fa + span {
    padding-left: 0;
  }
`;

const ButtonRoot = styled.button<{ size: Size; variant: Variant }>`
  all: unset;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[2]};
  border: 2px solid;
  border-radius: 2px;
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  line-height: ${({ theme }) => theme.typography.lineHeight.normal};
  ${transition}
  ${variants}

  &:hover {
    // disable text-decoration for link buttons
    text-decoration: none;
  }

  background-color: var(--bg-color);
  color: var(--label-color);
  border-color: var(--bg-color);

  &:before {
    content: ' ';
    position: absolute;
    inset: -2px;
    border: 1px solid var(--border-color);
    border-radius: 2px;
    ${transition}
    pointer-events: none;
  }

  &:disabled,
  &[data-disabled='true'] {
    cursor: default;
  }

  &:not([data-disabled='true']) {
    &:focus,
    &[data-focus='true'] {
      border-color: var(--border-color);
    }
  }

  ${sizes}
  ${hack}
`;

const ButtonIcon = styled(Icon)`
  color: var(--icon-color);
  ${transition}
`;

type Props = Overwrite<React.ComponentProps<typeof ButtonRoot>, { size?: Size }> & {
  tooltip?: React.ReactNode;
  /** Id to access button in test cases */
  testAnchorId?: string;
  isActive?: boolean;
};

const Button = React.forwardRef<HTMLButtonElement, Props>(
  ({ disabled, type = 'button', size = 's', tooltip, testAnchorId, onClick, isActive = false, ...props }, ref) => {
    function handleDisabled(e: React.MouseEvent<HTMLButtonElement>) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    return (
      <Tooltip content={tooltip}>
        {(registerTooltip) => (
          <ButtonRoot
            data-test={testAnchorId}
            data-active={isActive}
            type={type}
            size={size}
            {...props}
            {...(disabled ? { 'data-disabled': true, onClick: handleDisabled } : { onClick })}
            {...(tooltip ? { ...registerTooltip, ref: mergeRefs(ref, registerTooltip.ref) } : { ref })}
          />
        )}
      </Tooltip>
    );
  },
);

const ExternalLinkButton = ButtonRoot.withComponent('a');
const LinkButton = ButtonRoot.withComponent(Link);

export const NextButton = Object.assign(Button, { Icon: ButtonIcon });
export const NextExternalLinkButton = Object.assign(ExternalLinkButton, { Icon: ButtonIcon });
export const NextLinkButton = Object.assign(LinkButton, { Icon: ButtonIcon });
