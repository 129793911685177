import * as React from 'react';
export function useToggles(initialState = {}) {
    const [expandedNodes, setExpandedNodes] = React.useState(initialState);
    const createToggle = (name)=>()=>setExpandedNodes((nodes)=>({
                    ...nodes,
                    [name]: !nodes[name]
                }));
    const isToggled = (name)=>!!expandedNodes[name];
    return {
        isToggled,
        createToggle
    };
}
