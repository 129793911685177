// candidate for global types
/**
 * Clones an object and removes all __typename properties from an object.
 */ export function removeTypeFields(obj) {
    if (Array.isArray(obj)) {
        return obj.map(removeTypeFields);
    }
    if (typeof obj === 'object' && obj !== null) {
        return Object.fromEntries(Object.entries(obj).filter(([key])=>key !== '__typename').map(([key, val])=>[
                key,
                removeTypeFields(val)
            ]));
    }
    return obj;
}
