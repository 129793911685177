import { faDollarSign, faEuroSign, faPoundSign } from '@fortawesome/pro-solid-svg-icons';
export const currencies = [
    'USD',
    'EUR',
    'CNY',
    'GBP',
    'CHF'
];
export const defaultCurrency = 'USD';
/**
 * Currency conversion rates to USD.
 * sauce: org.txture/org.txture.core/src/main/java/org/txture/core/service/impl/CurrencyConversionServiceImpl.kt
 * Note: on conversionRate change, please notify @David ( StackIT crawlers need to be reexecuted)
 */ const conversionRates = {
    USD: 1,
    EUR: 1.082818,
    CNY: 0.156336,
    GBP: 1.302729,
    CHF: 1.053867
};
/** Symbols for supported currencies */ export const currencySymbol = {
    USD: '$',
    EUR: '€',
    GBP: '£'
};
/** Icons for supported currencies */ export const currencyIcon = {
    USD: faDollarSign,
    EUR: faEuroSign,
    GBP: faPoundSign
};
export const convertCurrency = (value, from, to)=>{
    return value * conversionRates[from] * (1 / conversionRates[to]);
};
