import * as React from 'react';
import { Icon } from '../Icon';
import { Button } from './Button';
import { ButtonStylingProps } from './ButtonStyles';
import { NextButton } from './NextButton';

type AsyncButtonProps = Omit<ButtonStylingProps, 'renderAsDisabled'> & {
  onClick: () => Promise<any>;
  icon?: React.ReactElement;
  children?: React.ReactNode;
};

/** @deprecated */
export const AsyncButton: React.FC<AsyncButtonProps> = ({ onClick, icon, ...props }) => {
  const [isSaving, setIsSaving] = React.useState(false);

  const handleClick = async () => {
    try {
      setIsSaving(true);
      await onClick();
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Button {...props} onClick={handleClick}>
      {isSaving ? <Icon icon="spinner" className="fa-pulse fa-3x fa-fw" fixedWidth /> : icon || null}
      {props.children}
    </Button>
  );
};

// -------------------------------------------------------------------------------------------------

type Props = React.ComponentProps<typeof NextButton> & {
  onClick: () => Promise<any>;
  icon?: React.ReactElement;
};

const AsyncButtonRoot: React.FC<Props> = ({ onClick, icon, ...props }) => {
  const [isSaving, setIsSaving] = React.useState(false);

  const handleClick = async () => {
    try {
      setIsSaving(true);
      await onClick();
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <NextButton {...props} onClick={handleClick}>
      {isSaving ? <NextButton.Icon icon="spinner" className="fa-pulse fa-3x fa-fw" fixedWidth /> : icon || null}
      {props.children}
    </NextButton>
  );
};

export const NextAsyncButton = Object.assign(AsyncButtonRoot, { Icon: NextButton.Icon });
